import React, { FormEvent, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ModalContentsState } from 'state/ModalContentsState';
import ProductService from 'services/api/ProductService';
import DeletionFailedModal from '../DeletionFailedModal/DeletionFailedModal';
import { track } from '@amplitude/analytics-browser';
import { CDSIDFromTokenSelector } from 'state/AccessTokenState';
import { OrgDetailsState } from 'state/OrgDetailsState';

import './DeleteProductModal.scss';

interface Props {
  productName: string;
  productId: string;
  orgNameParam: string;
}

export default function DeleteProductModal({ productName, productId, orgNameParam }: Props) {
  const orgDetails = useRecoilValue(OrgDetailsState);
  const setModalContents = useSetRecoilState(ModalContentsState);
  const cdsid = useRecoilValue(CDSIDFromTokenSelector);

  const [submitBtnIsEnabled, setSubmitBtnIsEnabled] = useState<boolean>(false);

  function openErrorModal() {
    setModalContents({
      title: 'Deletion Failed',
      component: (
        <DeletionFailedModal
          productName={productName}
          productId={productId}
          orgNameParam={orgNameParam}
        />
      ),
    });
  }

  function onSubmitForm(event: FormEvent) {
    event.preventDefault();

    if (submitBtnIsEnabled) {
      ProductService.deleteProduct(productId)
        .then(() => {
          track('delete product', { cdsid, org: orgDetails.name, product: productName });
          setModalContents(null);
          window.location.replace(`/${orgNameParam}`);
        })
        .catch(openErrorModal);
    }
  }

  return (
    <form className="delete-product-modal" onSubmit={onSubmitForm}>
      <h5 className="delete-product-title">Delete Product?</h5>
      <p className="delete-product-paragraph">
        Are you sure you want to delete the “{productName}” product from {orgDetails.name}? To
        confirm, please select the checkbox below. <br />
        <b>This action cannot be undone.</b>
      </p>
      <div className="checkbox-container">
        <input
          type="checkbox"
          id="deletionCheckbox"
          name="deletionCheckbox"
          onClick={(event) => setSubmitBtnIsEnabled(event.currentTarget.checked)}
          className="checkbox-input"
        />
        <label htmlFor="deletionCheckbox" className="checkbox-label">
          Delete {productName} from Newsie
        </label>
      </div>
      <div className="button-container">
        <button className="button-blue-dialog" onClick={() => setModalContents(null)}>
          Cancel
        </button>
        <button
          className="button-red delete-product-submit-btn"
          type="submit"
          disabled={!submitBtnIsEnabled}
        >
          Yes, Delete
        </button>
      </div>
    </form>
  );
}
