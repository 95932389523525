import React, { useEffect } from 'react';
import LoadingMessage from 'common/LoadingMessage/LoadingMessage';
import ErrorMessageView from 'common/ErrorMessageView/ErrorMessageView';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import { CurrentOrgSelector } from 'state/OrgsForDropDownState';
import { ProductsState } from 'state/ProductsForOrgState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import OrgContainer from './OrgContainer/OrgContainer';
import Product from 'types/Product';
import FilterAndActionsBar from './FilterAndActionsBar/FilterAndActionsBar';

import './OrgPageContent.scss';

export default function OrgPageContent(): React.ReactElement {
  const products = useRecoilValue(ProductsState);
  const { org = '' } = useParams();
  const organization = useRecoilValue(CurrentOrgSelector(org));

  const isActive = (product: Product) => product.done !== true;
  let totalActiveProducts = 0;

  useEffect(() => {
    document.title = organization.name + ' | Newsie';
  }, [organization.name]);

  switch (products.state) {
    case 'Loaded':
      totalActiveProducts = products.products.filter(isActive).length;
      return (
        <div className="org-page-content content-container">
          <div className="kanban-header">
            <FontAwesomeIcon
              className="org-icon fa-2x"
              icon={organization.icon as IconName}
              data-testid={organization.name + '-icon'}
            />
            <h1 className="org-title">{organization.name}</h1>
            <p className="org-subtitle">{organization.blurb}</p>
          </div>
          <FilterAndActionsBar />
          <OrgContainer totalActiveProducts={totalActiveProducts} organization={organization} />
        </div>
      );
    case 'Error':
      return <ErrorMessageView error={products.message} componentName="Kanban" />;
    case 'Loading':
    default:
      return <LoadingMessage />;
  }
}
