import { LightTooltip } from 'styles/material-ui/MaterialUIStyles';
import React, { useState } from 'react';
import TeamMemberType from 'types/TeamMember';
import { track } from '@amplitude/analytics-browser';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { CurrentOrgSelector } from 'state/OrgsForDropDownState';

interface Props {
  teamMember: TeamMemberType;
  trackingName: string;
  location: 'line' | 'product';
}

function TeamMemberComponent(props: Props) {
  const { teamMember, location, trackingName } = props;

  const { org = '' } = useParams();

  const organization = useRecoilValue(CurrentOrgSelector(org));

  const [toolTipContent, setToolTipContent] = useState<string>('');

  function trackCopy(teamMember: TeamMemberType) {
    track('click to copy teamMembers', {
      [location]: trackingName,
      'team member name': teamMember.name,
      'team member role': teamMember.title,
      org: `${organization.name} | ${organization.id}`,
      'app location': location,
    });
  }

  return (
    <LightTooltip
      className="cdsid-tooltip"
      title={toolTipContent}
      aria-label={teamMember.cdsid}
      data-testid="cdsid-tooltip"
      placement="top"
      arrow
    >
      <li tabIndex={-1} key={teamMember.cdsid} className={'report-sidebar-content'}>
        <div
          onClick={() => {
            trackCopy(teamMember);
            navigator.clipboard.writeText(teamMember.cdsid ? teamMember.cdsid : '');
            setToolTipContent('Copied!');
          }}
          onMouseEnter={() => {
            setToolTipContent('Click to copy CDSID: ' + teamMember.cdsid);
          }}
        >
          <div className={'semi-bold'}>{teamMember.name}</div>
          <div className="light">{teamMember.title}</div>
        </div>
      </li>
    </LightTooltip>
  );
}

export default TeamMemberComponent;
