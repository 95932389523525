import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Error, Event, Loading } from 'data/commonTypes';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Updates from './UpdateTab/UpdateTab';
import useScreenSize from 'hooks/useScreenSize';
import MobileTabsDropdown from 'common/MobileTabsDropdown/MobileTabsDropdown';
import { useRecoilState, useRecoilValue } from 'recoil';
import IsContentChangedState from 'state/ContentChangedState';
import ConfirmationModal from 'common/ConfirmationModal/DepricatedConfirmationModal/ConfirmationModal';
import Links from 'common/Links/Links';
import TeamMembers from 'common/TeamMember/TeamMembers';
import { CurrentOrgSelector } from 'state/OrgsForDropDownState';
import Overview from 'common/Overview/Overview';
import useActiveTab from 'hooks/useActiveTab';
import { track } from '@amplitude/analytics-browser';
import Product from 'types/Product';
import Page, { PageType, ParentType } from 'types/Page';
import RelevantLink from 'types/RelevantLink';
import TeamMember from 'types/TeamMember';
import getActiveTab from 'utils/getActiveTab';

import './ProductTabs.scss';

export type PageLoad = Loading | Loaded | Error;

export type Loaded = { state: 'Loaded'; page: Page | undefined };

interface Props {
  isDesktopAndTeamMemberOrAdmin: boolean;
  product: Product;
  updateProduct: (
    field: string,
    event: Event,
    content:
      | string
      | Array<TeamMember>
      | Array<RelevantLink>
      | boolean
      | Map<PageType, string[]>
      | undefined,
  ) => void;
}

export default function ProductTabs({
  isDesktopAndTeamMemberOrAdmin,
  product,
  updateProduct,
}: Props): React.ReactElement {
  const { productId = '', org = '' } = useParams();
  const { pathname } = useLocation();
  const isDesktop = useScreenSize();

  const organization = useRecoilValue(CurrentOrgSelector(org));
  const { activeTab, setActiveTab } = useActiveTab({
    type: 'product',
    typeNameId: `${product.name} | ${product.id}`,
  });
  const [isContentChanged, setIsContentChanged] = useRecoilState(IsContentChangedState);

  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState({
    show: false,
    index: 0,
  });

  useEffect(() => {
    if (product.id) {
      setIsContentChanged(false);
      setActiveTab(getActiveTab(pathname, true));
      track('view product', {
        org: `${organization.name} | ${organization.id}`,
        archived: product.done ?? false,
        product: `${product.name} | ${product.id}`,
      });
    }
  }, [product.id]);

  // @Todo dedup
  function displayProductLinks(): React.ReactElement {
    return product.relevantLinks ? (
      <Links
        links={product.relevantLinks}
        trackingName={`${product.name} | ${product.id}`}
        showEditPencil={isDesktopAndTeamMemberOrAdmin}
        orgParam={org}
        updateContent={updateProduct}
        location="product"
      />
    ) : (
      <></>
    );
  }

  function displayTeamMembers(): React.ReactElement {
    return product.teamMembers ? (
      <TeamMembers
        title="TEAM"
        trackingName={`${product.name} | ${product.id}`}
        teamMembers={product.teamMembers}
        updateContent={updateProduct}
        location="product"
      />
    ) : (
      <></>
    );
  }

  function displayTabMenu(): React.ReactElement {
    if (isDesktop) {
      return (
        <TabList>
          <Tab data-testid="overview-tab">Overview</Tab>
          <Tab data-testid="update-tab">Product Update</Tab>
        </TabList>
      );
    }
    return (
      <MobileTabsDropdown
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabMenuItemNames={['Overview', 'Product Update', 'Links & Team']}
      />
    );
  }

  return (
    <span className="product-tabs">
      <ConfirmationModal
        showModal={showUnsavedChangesModal?.show}
        setShowModal={(show) => {
          setShowUnsavedChangesModal({ ...showUnsavedChangesModal, show });
        }}
        exitWithoutSaving={() => {
          setIsContentChanged(false);
          setShowUnsavedChangesModal({ ...showUnsavedChangesModal, show: false });
          setActiveTab(showUnsavedChangesModal.index);
        }}
      />
      <Tabs
        className={`${isDesktop ? 'product-content-right' : ''}`}
        selectedIndex={activeTab}
        onSelect={(index) => {
          if (isContentChanged) {
            setShowUnsavedChangesModal({ show: true, index });
          } else {
            setActiveTab(index);
          }
        }}
      >
        {displayTabMenu()}
        <TabPanel className="tab-panel">
          <Overview
            parentProduct={{ ...product }}
            parentType={ParentType.PRODUCT}
            updateProductCallback={updateProduct}
          />
        </TabPanel>
        <TabPanel className="tab-panel">
          <Updates
            productId={productId}
            isDesktopAndTeamMemberOrAdmin={isDesktopAndTeamMemberOrAdmin}
          />
        </TabPanel>
        {!isDesktop && (
          <TabPanel>
            <div className="tab-content-container">
              {displayProductLinks()} {displayTeamMembers()}
            </div>
          </TabPanel>
        )}
      </Tabs>
    </span>
  );
}
