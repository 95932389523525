import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { CurrentOrgSelector } from 'state/OrgsForDropDownState';
import useOrgsForDropDown from 'hooks/useOrgsForDropDown';
import useProductHierarchy from 'hooks/useProductHierarchy';
import useProductsContext from 'hooks/useProductsContext';
import LoadingMessage from 'common/LoadingMessage/LoadingMessage';
import OrgPageContent from './OrgPageContent/OrgPageContent';
import { track } from '@amplitude/analytics-browser';

export default function OrgPage(): React.ReactElement {
  const { org = '' } = useParams();

  const { orgDetails } = useOrgsForDropDown();
  const { productHierarchy } = useProductHierarchy();
  useProductsContext();

  const organization = useRecoilValue(CurrentOrgSelector(org));

  useEffect(() => {
    if (orgDetails.name) {
      track('view org', { org: `${orgDetails.name} | ${orgDetails.id}` });
    }
  }, [orgDetails]);

  if (
    orgDetails.id &&
    orgDetails.id === organization.id &&
    productHierarchy.state === 'Loaded' &&
    productHierarchy.orgId === organization.id
  ) {
    return <OrgPageContent />;
  } else return <LoadingMessage />;
}
