import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useRecoilState, useRecoilValue } from 'recoil';
import IsContentChangedState from 'state/ContentChangedState';
import { CurrentOrgSelector } from 'state/OrgsForDropDownState';
import IsDesktopState from 'state/IsDesktopState';
import useActiveTab from 'hooks/useActiveTab';
import MobileTabsDropdown from 'common/MobileTabsDropdown/MobileTabsDropdown';
import Overview from 'common/Overview/Overview';
import ConfirmationModal from 'common/ConfirmationModal/DepricatedConfirmationModal/ConfirmationModal';
import ActiveProductsTab from './ActiveProductsTab/ActiveProductsTab';
import ProductHierarchy from 'types/ProductHierarchy';
import { ParentType } from 'types/Page';
import getActiveTab from 'utils/getActiveTab';
import LinksAndTeamMembers from '../LinksAndTeamMembers/LinksAndTeamMembers';

interface Props {
  productLine: ProductHierarchy;
  org: string;
}

export default function LineTabs({ productLine, org }: Props): React.ReactElement {
  const { pathname } = useLocation();
  const isDesktop = useRecoilValue(IsDesktopState);
  const { activeTab, setActiveTab } = useActiveTab({
    type: 'line',
    typeNameId: `${productLine.name} | ${productLine.id}`,
  });
  const [isContentChanged, setIsContentChanged] = useRecoilState(IsContentChangedState);
  const organization = useRecoilValue(CurrentOrgSelector(org));

  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState({
    show: false,
    index: 0,
  });

  useEffect(() => {
    if (productLine.id) {
      setIsContentChanged(false);
      setActiveTab(getActiveTab(pathname));
    }
  }, [productLine.id]);

  function printTabList(): React.ReactElement {
    if (isDesktop) {
      return (
        <TabList>
          <Tab data-testid="overview-tab">Overview</Tab>
          <Tab data-testid="active-products-tab">Active Products</Tab>
        </TabList>
      );
    }
    return (
      <MobileTabsDropdown
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabMenuItemNames={['Overview', 'Active Products', 'Links & Team']}
      />
    );
  }

  return (
    <>
      <ConfirmationModal
        showModal={showUnsavedChangesModal?.show}
        setShowModal={(show) => {
          setShowUnsavedChangesModal({ ...showUnsavedChangesModal, show });
        }}
        exitWithoutSaving={() => {
          setIsContentChanged(false);
          setShowUnsavedChangesModal({ ...showUnsavedChangesModal, show: false });
          setActiveTab(showUnsavedChangesModal.index);
        }}
      />
      <Tabs
        className={`${isDesktop ? 'product-content-right' : ''}`}
        selectedIndex={activeTab}
        onSelect={(index) => {
          if (isContentChanged) {
            setShowUnsavedChangesModal({ show: true, index });
          } else {
            setActiveTab(index);
          }
        }}
      >
        {printTabList()}
        <TabPanel className="tab-panel">
          <Overview
            parentProduct={{ ...productLine }}
            parentType={ParentType.LINE}
          />
        </TabPanel>
        <TabPanel className="tab-panel">
          <ActiveProductsTab productLine={productLine} organization={organization} />
        </TabPanel>
        {!isDesktop && (
          <TabPanel>
            <div className="tab-content-container">
              <LinksAndTeamMembers />
            </div>
          </TabPanel>
        )}
      </Tabs>
    </>
  );
}
