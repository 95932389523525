import SearchBar from './SearchBar/SearchBar';
import SearchTimeFilter from './SearchTimeFilter/SearchTimeFilter';
import Link from 'common/Link/Link';
import { track } from '@amplitude/analytics-browser';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { CurrentOrgSelector } from 'state/OrgsForDropDownState';
import { OrgDetailsState } from 'state/OrgDetailsState';
import { useParams } from 'react-router-dom';
import OrgActions from './OrgActions/OrgActions';

import './FilterAndActionsBar.scss';

export default function FilterAndActionsBar() {
  const { org = '' } = useParams();

  const organization = useRecoilValue(CurrentOrgSelector(org));
  const orgDetails = useRecoilValue(OrgDetailsState);

  return (
    <div className="kanban-actions sticky" id="kanban-actions">
      <div className="filter">
        <SearchBar />
        <SearchTimeFilter />
        {orgDetails.doneText && (
          <Link
            to={`archived`}
            onClick={() =>
              track('view archived products', {
                org: `${organization.name} | ${organization.id}`,
              })
            }
          >
            {' '}
            {orgDetails.doneText} Products
          </Link>
        )}
      </div>
      <div className="desktop-only">
        <OrgActions />
      </div>
    </div>
  );
}
