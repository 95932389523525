import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo_white from 'images/Logo_white.svg';
import { track } from '@amplitude/analytics-browser';
import { LightTooltip } from 'styles/material-ui/MaterialUIStyles';
import ContactSupportLink from 'common/ContactSupportLink/ContactSupportLink';
import SupportDocsLink from 'common/SupportDocsLink/SupportDocsLink';

import './Footer.scss';

export function Footer(): React.ReactElement {
  const navigate = useNavigate();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="top-footer-section">
          <div className="explore-section">
            <h4>Learn about our community:</h4>
            <button
              className="button-outline"
              onClick={() => {
                navigate('/all', { replace: true });
                track('navigate orgs', {
                  'app location': 'footer explore',
                });
              }}
            >
              EXPLORE
            </button>
          </div>
          <div className="help-section">
            <h4>Need help? Contact us:</h4>
            <div className="icons-group">
              <LightTooltip
                title="Contact Newsie Support using Viva Engage"
                data-testid="contact-support-tool-tip"
                placement="bottom"
              >
                <div>
                  <ContactSupportLink className="footer-link" location="footer" />
                </div>
              </LightTooltip>
              <SupportDocsLink className="footer-link" location="footer" />
            </div>
          </div>
        </div>
        <div className="bottom-footer-section">
          <div className="powered-by-section">
            <img className="icon logo-icon" src={logo_white} alt="Newsie Logo" />
            <h5 className="powered-by">
              Powered by{' '}
              <a
                className="footer-link"
                href="https://fordlabs.com"
                rel={'noopener noreferrer'}
                title="to Ford Labs"
                target="_blank"
              >
                FordLabs
              </a>{' '}
              & Ford Pro Technology
            </h5>
          </div>
          <div className="CIA-rating-section">
            <p>
              {`Ford's CIA rating for Newsie is 1-1-1. Ford data classified proprietary or lower is
            allowed. Do not store SPII data in Newsie. `}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
