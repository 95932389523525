import moment from 'moment';
import React, { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import FilteredProductCountState from 'state/FilteredProductCountState';
import { OrgDetailsState } from 'state/OrgDetailsState';
import useSearchFilters from 'hooks/useSearchFilters';
import { generateProductURL } from 'utils/utils';
import Link from 'common/Link/Link';
import ProductCard from './ProductCard/ProductCard';

import styles from './ProductLine.module.scss';
import { track } from '@amplitude/analytics-browser';
import ProductHierarchy from 'types/ProductHierarchy';
import Product from 'types/Product';

interface Props {
  line: ProductHierarchy;
  productsInLine: Product[];
  stagesMap?: Map<string, number>;
  orgUrl: string;
}

export default function ProductLine({
  line,
  productsInLine,
  stagesMap,
  orgUrl,
}: Props): React.ReactElement {
  const orgDetails = useRecoilValue(OrgDetailsState);
  const searchFilters = useSearchFilters();
  const setFilteredProductCount = useSetRecoilState(FilteredProductCountState);

  useEffect(() => {
    setFilteredProductCount((prev) => prev + productsInLine.length);
    return () => {
      setFilteredProductCount((prev) => prev - productsInLine.length);
    };
  }, [searchFilters]);

  function printProductLineName(line: ProductHierarchy): React.ReactElement {
    return (
      <Link
        className={`${styles.displayName} standard-link`}
        data-testid="product-line-link"
        to={`/${orgUrl}/line/${encodeURIComponent(line.id)}/${encodeURIComponent(
          line.name,
        )}/profile`}
        onClick={() =>
          track('navigate lines', {
            org: orgDetails.name,
            line: `${line.id} | ${line.name}`,
            'app location': 'org',
          })
        }
      >
        {line.name}
      </Link>
    );
  }

  function printProducts(productsToPrint: Product[]) {
    function getStageIndex(stageName: string | undefined): number | undefined {
      return stagesMap && stageName ? stagesMap.get(stageName.toLowerCase()) : undefined;
    }
    return (
      <div className={styles.displayProducts}>
        {productsToPrint.map((product) => {
          return (
            <div key={`product-card-${product.id}`} className={styles.flexRow}>
              <ProductCard
                name={product.name}
                stage={product.stage}
                url={generateProductURL(orgUrl, product)}
                lastUpdatedTimeStamp={
                  product.lastUpdatedTimeStamp &&
                  moment(product.lastUpdatedTimeStamp).format('MMM D')
                }
                key={product.id}
                stageIndex={getStageIndex(product.stage)}
              />
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={styles.productLine} data-testid="product-line">
      <div className={styles.productLineDivider}>
        <div className={styles.lineSeparator} />
      </div>
      {printProductLineName(line)}
      {printProducts(productsInLine)}
    </div>
  );
}
