import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import NoResultsFound from 'images/no-results-image.svg';
import { CurrentOrgSelector } from 'state/OrgsForDropDownState';
import { GetSearchCriteriaWithValueSelector } from 'state/TextToSearchState';
import TimeFilterSearchState, { TimeFilterOptions } from 'state/TimeFilterSearchState';
import { hasScrolled } from 'utils/utils';
import { track } from '@amplitude/analytics-browser';

import './NoResultsMessage.scss';

interface Props {
  setTextToSearch: (textToSearch: string) => void;
}

export default function NoResultsMessage({ setTextToSearch }: Props): React.ReactElement {
  const { org = '' } = useParams();
  const organization = useRecoilValue(CurrentOrgSelector(org));
  const searchCriteriaWithValue = useRecoilValue(GetSearchCriteriaWithValueSelector);
  const setTimeFilter = useSetRecoilState(TimeFilterSearchState);

  useEffect(() => {
    track('filter org', {
      results: 'missing',
      'filter type': 'text filter',
      'filter selection': searchCriteriaWithValue.criteria.toLocaleLowerCase(),
      'filter term': searchCriteriaWithValue.value,
      org: `${organization.name} | ${organization.id}`,
      scrolled: hasScrolled('kanban-actions'),
    });
  }, []);

  return (
    <div className="no-results">
      <div className="left-column">
        <h2>{"Oh no! We couldn't find what you're looking for!"}</h2>
        <p>No results found. Please try updating the filters you have applied above.</p>
        <button
          className="button-blue"
          data-testid="kanban-clear-search-button"
          onClick={() => {
            setTextToSearch('');
            setTimeFilter(TimeFilterOptions.UPDATED_ANY_TIME);
          }}
        >
          CLEAR SEARCH
        </button>
      </div>
      <img
        src={NoResultsFound}
        alt="Slightly sad person in business casual on a hike with their favorite empty box."
      />
    </div>
  );
}
