import React, { PropsWithChildren } from 'react';
import TokenService from 'services/TokenService';
import HandleRedirect from './HandleRedirect';

export default function AuthGuard(props: PropsWithChildren<unknown>): React.ReactElement | null {
  if (window.location.pathname.includes('/oauth/redirect')) {
    return <HandleRedirect />;
  }

  if (!isAuthenticated()) {
    return redirectToAdfs();
  }

  return <>{props.children}</>;
}

function redirectToAdfs(): null {
  const clientId = process.env.REACT_APP_ADFS_CLIENT_ID;
  const resource = process.env.REACT_APP_ADFS_RESOURCE_URI;

  const origin = window.location.origin;

  const redirectUri = `${origin}/oauth/redirect${
    window.location.pathname + encodeURIComponent(window.location.search ?? '')
  }`;
  const queryParams = [
    `client_id=${clientId}`,
    `resource=${resource}`,
    `response_type=token+id_token`,
    `redirect_uri=${redirectUri}`,
  ].join('&');

  const oauthIssuerUrl = process.env.REACT_APP_ADFS_URL;
  window.location.href = `${oauthIssuerUrl}?${queryParams}`;

  return null;
}

function isNotEmptyOrNull(str: string | null | undefined): boolean {
  return !!str;
}

function isAuthenticated(): boolean {
  const token = TokenService.getToken();
  const expiresAt = TokenService.getExpiry();
  return isNotEmptyOrNull(token) && expiresAt > Date.now();
}
