import React, { useEffect, useState } from 'react';
import './StoryJamBanner.scss';
import Icon from 'common/Icon/Icon';
import { track } from '@amplitude/analytics-browser';

interface ButtonProps {
  url: string;
  text: string;
}

export default function StoryJamBanner(): React.ReactElement {
  const [random, setRandom] = useState(-1);
  const buttonProps: ButtonProps[] = [
    {
      url: 'https://storyjam.ford.com/?utm_source=newsie&utm_medium=banner&utm_campaign=try_storyjam_a',
      text: 'Try StoryJam',
    },
    {
      url: 'https://storyjam.ford.com/?utm_source=newsie&utm_medium=banner&utm_campaign=try_storyjam_b',
      text: 'Try it Now',
    },
  ];

  useEffect(() => {
    const tempRandom = Math.round(Math.random());
    setRandom(tempRandom);
    track('view StoryJam Newsie Banner', {
      'app location': 'newsie home',
      cta: `button ${tempRandom === 0 ? 'a' : 'b'}`,
    });
  }, []);

  const onClick = () => {
    track('click StoryJam Newsie Banner', {
      'app location': 'newsie home',
      cta: `button ${random === 0 ? 'a' : 'b'}`,
    });
  };

  return (
    <section className="banner">
      <div className="banner-container">
        <div className="banner-image">
          <Icon name="storyjam/banana" alt="StoryJam Banana" />
        </div>
        <div className="banner-text-container">
          <span className="banner-text-1">Need a new story sizing, ice breaker or retro tool?</span>
          <span className="banner-text-2">
            Try StoryJam. A tool that allows all
            <br /> team members to voice their opinions.
          </span>
        </div>
        {random != -1 && (
          <a
            href={buttonProps[random].url}
            onClick={onClick}
            target="_blank"
            rel="noreferrer"
            data-testid="try-button"
            className="try-link"
            aria-label="Try the StoryJam app"
          >
            <span>{buttonProps[random].text}</span>
          </a>
        )}
      </div>
    </section>
  );
}
