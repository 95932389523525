import TeamMember from '../types/TeamMember';

const newsieAdminList: string[] = [
  // please use all lower case
  'rliken',
  'newsie1',
  'lwilki22',
  //'akudlins',
  'coconn33',
  'bpeter90',
  'jhudso78',
  'hanaghan',
  'nkilpat1',
  'tlo3',
  'kgeorvas',
  'aclift19',
  'jmayorg1',
];

const PermissionService = {
  isNewsieAdmin(cdsid: string): boolean {
    return newsieAdminList.includes(cdsid);
  },
  isTeamMember(cdsid: string, teamMembers: TeamMember[]): boolean {
    return teamMembers
      ?.filter((teamMember) => teamMember.cdsid !== null)
      .map((teamMember) => teamMember.cdsid?.toLowerCase())
      .includes(cdsid);
  },
  isTeamMemberOrAdmin(cdsid: string, teamMembers: TeamMember[] | null = []): boolean {
    return (
      PermissionService.isNewsieAdmin(cdsid) ||
      PermissionService.isTeamMember(cdsid, teamMembers || [])
    );
  },
};

export default PermissionService;
