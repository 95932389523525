import { atom } from 'recoil';
import React from 'react';

export interface ModalContents {
  title: string;
  component: React.ReactElement;
}

export const ModalContentsState = atom<ModalContents | null>({
  key: 'modalContentsState',
  default: null,
});
