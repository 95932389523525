import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { AmplitudeTracking } from 'data/commonTypes';
import ErrorMessageState from 'state/ErrorMessageState';
import { ProductsState } from 'state/ProductsForOrgState';
import { extractErrorMessage, generateProductURL } from 'utils/utils';
import ErrorTag from 'common/ErrorTag/ErrorTag';
import { AddProductInfoSection } from './AddProductInfoSection/AddProductInfoSection';
import { AddTeamMembersSection } from './AddTeamMembersSection/AddTeamMembersSection';
import { track } from '@amplitude/analytics-browser';
import OrgForKanban from 'types/OrgForKanban';
import Product from 'types/Product';
import TeamMember from 'types/TeamMember';
import ProductService from 'services/api/ProductService';

import './AddProductForm.scss';

interface Props {
  orgDetails: OrgForKanban;
  icon?: string;
  orgUrl: string;
}

export default function AddProductForm({ orgDetails, icon, orgUrl }: Props): React.ReactElement {
  const navigate = useNavigate();
  const [productsStateValue, setProductsStateValue] = useRecoilState(ProductsState);

  const [amplitudeTracking, setAmplitudeTracking] = useState<AmplitudeTracking | undefined>(
    undefined,
  );
  const [errorMessage, setErrorMessage] = useRecoilState(ErrorMessageState);
  const [product, setProduct] = useState<Product>(
    (icon
      ? {
          orgId: orgDetails.id,
          icon: icon,
        }
      : { orgId: orgDetails.id }) as Product,
  );
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([
    { name: '', cdsid: '', title: '' },
  ]);

  useEffect(() => {
    const stage = orgDetails.stages ? orgDetails.stages[0].name.toLowerCase() : undefined;
    setProduct({ ...product, orgId: orgDetails.id, stage });
  }, [orgDetails]);

  useEffect(() => {
    if (!errorMessage.errorMessage) {
      setErrorMessage({ ...errorMessage, amplitudeErrorMessage: '' });
    }
  }, [product]);

  async function handleOnSubmit() {
    try {
      setProduct((prev) => {
        const tempProduct = prev;
        tempProduct.teamMembers = teamMembers;
        return tempProduct;
      });
      await ProductService.addProduct(product).then((savedProduct: Product) => {
        track('submit add new product form', {
          org: `${orgDetails.name} | ${orgDetails.id}`,
          product: product.name,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          'initial team members': product.teamMembers!.length,
        });
        if (productsStateValue.state === 'Loaded') {
          setProductsStateValue({
            ...productsStateValue,
            products: [...productsStateValue.products, savedProduct],
          });
        }
        navigate(`${generateProductURL(orgUrl, savedProduct)}/profile`);
      });
    } catch (error) {
      const errMesg = extractErrorMessage(error, 'Error adding new product');
      setAmplitudeTracking({
        eventName: 'view error',
        data: {
          org: `${orgDetails.name} | ${orgDetails.id}`,
          product: product.name,
          'app location': 'add new product',
        },
      });
      setErrorMessage({ amplitudeErrorMessage: errMesg, errorMessage: errMesg });
    }
  }

  interface SectionProps {
    children: React.ReactNode;
    title: string;
  }
  const AddProductSection = ({ children, title }: SectionProps) => {
    return (
      <>
        <h2 className="caps-header add-product-section-title">{title}</h2>
        {children}
      </>
    );
  };

  return (
    <>
      <ErrorTag amplitudeTracking={amplitudeTracking} />
      <form
        className="add-product-form"
        onSubmit={async (e) => {
          e.preventDefault();
          await handleOnSubmit();
        }}
      >
        <AddProductInfoSection product={product} setProduct={setProduct} orgDetails={orgDetails} />
        <AddProductSection title="Add Team Members">
          <AddTeamMembersSection teamMembers={teamMembers} setTeamMembers={setTeamMembers} />
        </AddProductSection>
        <button type="submit" className="button-blue">
          Save
        </button>
      </form>
    </>
  );
}
