import React, { useEffect, useState } from 'react';
import { Moment } from 'moment';
import Modal from 'common/Modal/DepricatedModal/Modal';
import Calendar from '../Calendar/Calendar';
import { getLatestAddNewUpdateDate, shouldDisableDate } from 'utils/calendarDate';
import { TrackUpdateAction } from '../UpdateTab';

interface Props {
  trackClick: (action: TrackUpdateAction, date: string) => void;
  datesForPage: Array<string>; // example: [ '1-2024', '2-2024' ] <-- [week of year]-[year]
  setActionToAdd: () => void;
  setSelectedDate: (week: string) => void;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
}

export default function AddUpdateModal({
  trackClick,
  setSelectedDate,
  setActionToAdd,
  datesForPage,
  isEditing,
  setIsEditing,
}: Props): React.ReactElement {
  const [newSelectedDate, setNewSelectedDate] = useState<Moment>(
    getLatestAddNewUpdateDate(datesForPage),
  );
  useEffect(() => {
    setNewSelectedDate(getLatestAddNewUpdateDate(datesForPage));
  }, [isEditing]);

  return (
    <Modal
      title="ADD NEW UPDATE"
      isOpenModal={isEditing}
      showCloseIcon={true}
      closeHandler={() => setIsEditing(false)}
    >
      <Calendar
        selectedDate={newSelectedDate}
        setSelectedDate={setNewSelectedDate}
        shouldDisableDate={(date) => shouldDisableDate(date, datesForPage)}
        addingNewUpdate={true}
      />
      <button
        className="button-blue"
        onClick={() => {
          setActionToAdd();
          setSelectedDate(newSelectedDate.format('w-gggg'));
          trackClick(TrackUpdateAction.START_NEW_UPDATE, newSelectedDate.format('w-gggg'));
          setIsEditing(false);
        }}
      >
        Add Update
      </button>
    </Modal>
  );
}
