import React from 'react';

interface Props {
  continueHandler: () => void;
  exitHandler: () => void;
}

export function UnsavedChanges({ continueHandler, exitHandler }: Props): React.ReactElement {
  return (
    <form
      id="modal-action-message"
      onSubmit={(e) => {
        e.preventDefault();
        continueHandler();
      }}
    >
      <span>
        There are <b>unsaved</b> changes that will be lost. If you would like to save the changes,
        press the &quot;Continue Editing&quot; button.
      </span>
      <div className="modal-button-container">
        <button className={'button-blue'} type="submit">
          Continue Editing
        </button>
        <button className="button-red" onClick={exitHandler} type="button">
          Exit without Saving
        </button>
      </div>
    </form>
  );
}
