import React, { ReactElement, useEffect } from 'react';
import StoryJamBanner from './StoryJamBanner/StoryJamBanner';
import useOrgsForDropDown from 'hooks/useOrgsForDropDown';
import LoadingMessage from 'common/LoadingMessage/LoadingMessage';
import ErrorMessageView from 'common/ErrorMessageView/ErrorMessageView';
import { track } from '@amplitude/analytics-browser';
import TitleBanner from './TitleBanner/TitleBanner';
import AllOrgsList from './AllOrgsList/AllOrgsList';
import CallToActionSection from './CallToActionSection/CallToActionSection';

export default function HomePage(): ReactElement {
  const { orgsForDropDown } = useOrgsForDropDown();

  useEffect(() => {
    document.title = 'Newsie';
    track('view home');
  }, []);

  switch (orgsForDropDown.state) {
    case 'Loaded':
      return (
        <>
          <StoryJamBanner />
          <div className="content-container">
            <TitleBanner />
            <AllOrgsList orgs={orgsForDropDown.organizations} />
            <CallToActionSection />
          </div>
        </>
      );
    case 'Error':
      return <ErrorMessageView componentName="Nav" error={orgsForDropDown.message} />;
    case 'Loading':
    default:
      return <LoadingMessage />;
  }
}
