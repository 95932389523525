import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment, { Moment } from 'moment';
import React from 'react';
import { formatWeekToFullDate, reportExistsForDate } from 'utils/calendarDate';
import useScreenSize from 'hooks/useScreenSize';
import { LightTooltip } from 'styles/material-ui/MaterialUIStyles';
import Calendar from '../Calendar/Calendar';
import { Actions, TrackUpdateAction } from '../UpdateTab';

import './UpdateCalendar.scss';

interface Props {
  datesForPage: string[];
  action: Actions;
  selectedDate: string;
  setSelectedDate: (date: string) => void;
  trackClick: (action: TrackUpdateAction, week?: string, appLocation?: string) => void;
}

export function formatToParsableDate(date: string, daysToAdd: number): Date {
  const parsedDate = moment(date, 'w-gggg').add(daysToAdd, 'day');
  return parsedDate.toDate();
}

export default function UpdateDatesCalendar({
  datesForPage,
  action,
  selectedDate,
  setSelectedDate: setSelectedDate,
  trackClick,
}: Props): React.ReactElement {
  const isDesktop = useScreenSize();
  const index = datesForPage.findIndex((date) => date === selectedDate);

  function printWeekDropdown() {
    if (action === Actions.view) {
      return (
        <Calendar
          selectedDate={moment(selectedDate, 'w-gggg').add(1, 'day')}
          setSelectedDate={(date: Moment) => {
            setSelectedDate(date.format('w-gggg'));
            trackClick(TrackUpdateAction.VIEW_HISTORICAL_UPDATE, date.format('w-gggg'), 'calendar');
          }}
          shouldDisableDate={(date) => !reportExistsForDate(datesForPage, date.toDate())}
          addingNewUpdate={false}
          disabledMode={action !== Actions.view || !isDesktop}
          minDate={formatToParsableDate(datesForPage[datesForPage.length - 1], 1)}
          maxDate={formatToParsableDate(datesForPage[0], datesForPage.length === 1 ? 2 : 1)}
        />
      );
    }
    return (
      <div className="add-or-edit-date-label" data-testid="add-or-edit-date-label">
        Update for {formatWeekToFullDate(selectedDate)}
      </div>
    );
  }

  function printUpdateArrowLeft() {
    const isDisabled = action !== Actions.view || index === datesForPage.length - 1;

    return (
      <LightTooltip
        className="back-arrow-tooltip"
        title="Go to Previous Update"
        data-testid="back-arrow-tooltip"
        placement="top-start"
        arrow
      >
        <span>
          <button
            className={`button-arrow ${isDisabled ? 'disabled' : ''}`}
            aria-label="view previous update"
            data-testid="previous-update"
            disabled={isDisabled}
            onClick={() => {
              trackClick(
                TrackUpdateAction.VIEW_HISTORICAL_UPDATE,
                datesForPage[index + 1],
                'back arrow',
              );
              setSelectedDate(datesForPage[index + 1]);
            }}
          >
            <FontAwesomeIcon icon={'caret-left' as IconName} />
          </button>
        </span>
      </LightTooltip>
    );
  }

  function printUpdateArrowRight() {
    const isDisabled = action !== Actions.view || index === 0;

    return (
      <>
        <LightTooltip
          className="forward-arrow-tooltip"
          title="Go to Next Update"
          data-testid="forward-arrow-tooltip"
          placement="top-start"
          arrow
        >
          <span>
            <button
              className={`button-arrow ${isDisabled ? 'disabled' : ''}`}
              aria-label="view next update"
              data-testid="next-update"
              disabled={isDisabled}
              onClick={() => {
                trackClick(
                  TrackUpdateAction.VIEW_HISTORICAL_UPDATE,
                  datesForPage[index - 1],
                  'forward arrow',
                );
                setSelectedDate(datesForPage[index - 1]);
              }}
            >
              <FontAwesomeIcon icon={'caret-right' as IconName} />
            </button>
          </span>
        </LightTooltip>
      </>
    );
  }
  function viewCalendarOrLabelForHistory(): React.ReactElement {
    if (isDesktop) {
      return (
        <>
          {printWeekDropdown()}
          {action === Actions.view && printUpdateArrowLeft()}
        </>
      );
    }
    return (
      <>
        {action === Actions.view && printUpdateArrowLeft()}
        <div className={'current-update-date-label'}>
          Update for {formatWeekToFullDate(selectedDate)}
        </div>
      </>
    );
  }
  return (
    <div className="update-select-controls">
      {viewCalendarOrLabelForHistory()}
      {action === Actions.view && printUpdateArrowRight()}
    </div>
  );
}
