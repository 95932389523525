import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import reportMetricsToBackend from 'utils/reportMetricsToBackend';
import { RecoilRoot } from 'recoil';
import AppWrapper from 'common/AppWrapper/AppWrapper';
import { init } from '@amplitude/analytics-browser';

init(process.env.REACT_APP_AMPLITUDE_API_KEY as string);

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <Router>
        <AppWrapper />
      </Router>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals(reportMetricsToBackend);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
