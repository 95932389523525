import React, { FormEventHandler } from 'react';

import './ConfirmationModal.scss';
interface Props {
  testId?: string;
  className?: string;
  children?: React.ReactElement[] | React.ReactElement;
  title: string;
  subtitle: string;
  confirmButtonText?: string;
  cancelButtonText: string;
  onConfirm?: FormEventHandler;
  onCancel: () => void;
}

function ConfirmationModal(props: Props) {
  const {
    className,
    children,
    title,
    subtitle,
    confirmButtonText = 'Submit',
    cancelButtonText = 'Cancel',
    onConfirm,
    onCancel,
  } = props;

  return (
    <form
      id="modal-delete-item"
      onSubmit={(e) => {
        e.preventDefault();
        onConfirm && onConfirm(e);
      }}
      className={'confirmation-modal ' + className}
    >
      <div className="confirmation-modal-body">
        <div className="confirmation-modal-title bold">{title}</div>
        <p className="confirmation-modal-text">{subtitle}</p>
        {children}
      </div>
      <div className="confirmation-modal-footer">
        <button type="button" className="button-blue-dialog" onClick={onCancel}>
          {cancelButtonText}
        </button>
        {onConfirm && (
          <button className="button-red" type="submit">
            {confirmButtonText}
          </button>
        )}
      </div>
    </form>
  );
}

export default ConfirmationModal;
