import React, { useEffect } from 'react';
import ErrorLogo from '../../images/error-logo.svg';
import { track } from '@amplitude/analytics-browser';

import './ErrorMessageView.scss';

interface Props {
  error: unknown;
  componentName: string;
  cause?: string;
}

export default function ErrorMessageView(props: Props): React.ReactElement {
  useEffect(() => {
    track('view error', {
      error: props.error,
      cause: props.cause,
      'app location': 'coffee page',
      'component name': props.componentName,
    });
  }, []);

  return (
    <div className="error-container">
      <div className="error-content">
        <span className="error-title">Oops, we haven’t had our morning coffee yet!</span>
        <span className="error-text">
          Please try refreshing the page or send us a note at &nbsp;
          <a
            href="mailto:newsie@ford.com?subject=Newsie%20Support%20Ticket"
            onClick={() => {
              track('click email link', {
                'app location': 'error page',
              });
            }}
          >
            newsie@ford.com
          </a>
        </span>
        <span className="refresh">
          <button onClick={() => location.reload()}>REFRESH</button>
        </span>
        <span className="support-links">
          We’re also available via:
          <a
            href="https://ford-labs.slack.com/archives/C01G49PAARM"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              track('click slack link', {
                'app location': 'error page',
              });
            }}
          >
            Slack Channel
          </a>
          <a
            href="https://www.webexteams.ford.com/space?r=ia17"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              track('click webex teams link', {
                'app location': 'error page',
              });
            }}
          >
            Webex Teams
          </a>
          <a
            href="https://azureford.sharepoint.com/sites/newsie"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              track('click docs link', {
                'app location': 'error page',
              });
            }}
          >
            Newsie Support site
          </a>
        </span>
      </div>
      <div>
        <img alt={'error'} src={ErrorLogo} />
      </div>
    </div>
  );
}
