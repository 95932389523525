import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import useOrgsForDropDown from 'hooks/useOrgsForDropDown';
import { useRecoilState, useRecoilValue } from 'recoil';
import ErrorMessageState from 'state/ErrorMessageState';
import ErrorMessageView from 'common/ErrorMessageView/ErrorMessageView';
import { ProductHierarchyByIdInState } from 'state/ProductHierarchyState';
import useProductHierarchy from 'hooks/useProductHierarchy';
import LoadingMessage from 'common/LoadingMessage/LoadingMessage';
import { track } from '@amplitude/analytics-browser';
import ProductLinePageContent from './ProductLinePageContent/ProductLinePageContent';

export default function ProductLinePage(): React.ReactElement {
  const { lineId = '' } = useParams();

  const { orgDetails } = useOrgsForDropDown();
  const { productHierarchy } = useProductHierarchy();

  const [errorMessage, setErrorMessage] = useRecoilState(ErrorMessageState);
  const productLine = useRecoilValue(ProductHierarchyByIdInState(lineId));

  useEffect(() => {
    setErrorMessage({ ...errorMessage, amplitudeErrorMessage: '' });
    if (orgDetails.id && productLine) {
      track('view line', {
        org: orgDetails.name,
        line: `${productLine.name} | ${productLine.id}`,
      });
    }
  }, [productLine, orgDetails.id]);

  switch (productHierarchy.state) {
    case 'Loaded':
      return <ProductLinePageContent />;
    case 'Error':
      return <ErrorMessageView componentName="Line" error={productHierarchy.message} />;
    case 'Loading':
    default:
      return <LoadingMessage />;
  }
}
