import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { ModalAction, OrgDetailsEditModal } from '../../../OrgDetailsEditModal/OrgDetailsEditModal';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { OrgDetailsState } from 'state/OrgDetailsState';
import useProductsContext from 'hooks/useProductsContext';
import DeleteProductHierarchyConfirmation from './DeleteProductHierarchyConfirmation/DeleteProductHierarchyConfirmation';
import { ModalContentsState } from 'state/ModalContentsState';
import { LightTooltip } from 'styles/material-ui/MaterialUIStyles';
import { CDSIDFromTokenSelector } from 'state/AccessTokenState';
import { track } from '@amplitude/analytics-browser';
import ProductHierarchy, { HierarchyType } from 'types/ProductHierarchy';
import Accordion from './StyledAccordian/Accordian';
import AccordionSummary from './StyledAccordian/AccordianSummary';
import AccordionDetails from './StyledAccordian/AccordianDetails';
import LinesForGroup from './LinesForGroup/LinesForGroup';

import './GroupAndLinesAccordion.scss';

interface Props {
  group: ProductHierarchy;
  lines: ProductHierarchy[];
  index: number;
  isEditMode: boolean;
  toolBarHandler: {
    edit: (productHierarchy: ProductHierarchy | undefined) => void;
  };
  expanded: number;
  setExpanded: (expanded: number) => void;
  dragHandleSpan: React.ReactElement;
}

export default function GroupsAndLinesAccordion(props: Props) {
  const { group, lines, index, isEditMode, toolBarHandler, expanded, setExpanded, dragHandleSpan } =
    props;

  useProductsContext(); //TODO NOT

  const cdsid = useRecoilValue(CDSIDFromTokenSelector);
  const setModalContents = useSetRecoilState(ModalContentsState);
  const orgDetails = useRecoilValue(OrgDetailsState);

  const [modalAction, setModalAction] = useState<ModalAction>(ModalAction.ADD);
  const [productHierarchyModalState, setProductHierarchyModalState] = useState<
    ProductHierarchy | undefined
  >(undefined);

  const groupHasNoLines = lines.length == 0;

  function trackEvent(eventName: string) {
    track(eventName, {
      org: `${orgDetails.name} | ${orgDetails.id}`,
      cdsid: cdsid,
    });
  }

  return (
    <Accordion
      expanded={expanded == index}
      onChange={() => setExpanded(expanded === index ? -1 : index)}
    >
      <AccordionSummary
        aria-controls={`panel-content-${index}`}
        id={`panel-header-${index}`}
        iseditmode={isEditMode}
        data-testid={`panel-summary-${index}`}
      >
        <span>{group.name}</span>
        {isEditMode && (
          <div className="tool-bar">
            <button
              data-testid={`${group.name}-edit-pencil`}
              aria-label="edit lines"
              className="edit-pencil"
              onClick={() => toolBarHandler.edit(group)}
            >
              <FontAwesomeIcon icon={'pen' as IconName} />
            </button>
            {groupHasNoLines && (
              <button
                aria-label="delete group"
                className="trash-icon"
                onClick={() =>
                  setModalContents({
                    title: 'Delete Group',
                    component: (
                      <DeleteProductHierarchyConfirmation
                        name={group.name}
                        id={group.id}
                        title={'Delete Group?'}
                        type="group"
                      />
                    ),
                  })
                }
              >
                <FontAwesomeIcon icon={'trash-can' as IconName} />
              </button>
            )}
            <LightTooltip
              title={'Drag & Drop to Reorder'}
              aria-label={`Drag & Drop to Reorder ${group.name}`}
              placement="top"
              arrow
            >
              {dragHandleSpan}
            </LightTooltip>
          </div>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <>
          {LinesForGroup(lines, isEditMode, toolBarHandler)}
          {isEditMode && (
            <>
              {groupHasNoLines && (
                <div className="lines italic">there are no lines in this group</div>
              )}
              <div className="add-line-button">
                <button
                  data-testid={`${group.name}-new-line`}
                  className="button-add"
                  aria-label="add line"
                  onClick={() => {
                    trackEvent('add new line');
                    setModalAction(ModalAction.ADD);
                    setProductHierarchyModalState({
                      name: '',
                      orgId: orgDetails.id,
                      type: HierarchyType.LINE,
                      parentId: group.id,
                      teamMembers: null,
                    } as ProductHierarchy);
                  }}
                >
                  <FontAwesomeIcon icon={'plus' as IconName} />
                  Add New Line
                </button>
              </div>
            </>
          )}
          {productHierarchyModalState && (
            <OrgDetailsEditModal
              productHierarchyModalState={productHierarchyModalState}
              setProductHierarchyModalState={setProductHierarchyModalState}
              setExpanded={setExpanded}
              modalAction={modalAction}
              expanded={expanded}
            />
          )}
        </>
      </AccordionDetails>
    </Accordion>
  );
}
