import React from 'react';
import { Header } from 'common/Header/Header';
import ErrorTag from 'common/ErrorTag/ErrorTag';
import LineTabs from 'pages/product-line-page/ProductLinePageContent/LineTabs/LineTabs';
import { useRecoilValue } from 'recoil';
import { ProductHierarchyByIdInState } from 'state/ProductHierarchyState';
import useScreenSize from 'hooks/useScreenSize';
import { useParams } from 'react-router';
import ErrorMessageState from 'state/ErrorMessageState';
import { OrgDetailsState } from 'state/OrgDetailsState';
import LinksAndTeamMembers from './LinksAndTeamMembers/LinksAndTeamMembers';

import './ProductLinePageContent.scss';

export default function ProductLinePageContent(): React.ReactElement {
  const { lineId = '', org = '' } = useParams();

  const isDesktop = useScreenSize();

  const orgDetails = useRecoilValue(OrgDetailsState);
  const productLine = useRecoilValue(ProductHierarchyByIdInState(lineId));
  const errorMessage = useRecoilValue(ErrorMessageState);
  const productGroup = useRecoilValue(
    ProductHierarchyByIdInState(productLine?.parentId ?? ''),
  )?.name;

  return productLine ? (
    <>
      <Header productName={productLine.name} superHeaderText={<span> &gt; {productGroup}</span>} />
      <div
        className="content-container product-container-flex line-content-container"
        id="content-container"
      >
        {errorMessage.amplitudeErrorMessage && (
          <ErrorTag
            amplitudeTracking={{
              eventName: 'view error',
              data: {
                'app location': 'save line overview edits',
                org: `${orgDetails.name} | ${orgDetails.id}`,
                line: `${productLine.name} | ${productLine.id}`,
                error: errorMessage.amplitudeErrorMessage,
              },
            }}
          />
        )}
        {isDesktop && (
          <div className="product-content-left">
            <LinksAndTeamMembers />
          </div>
        )}
        <LineTabs productLine={productLine} org={org} />
      </div>
    </>
  ) : (
    <></>
  );
}
