import React from 'react';
import { MenuItem, Select, SelectChangeEvent, ThemeProvider } from '@mui/material';
import { muiTheme } from 'styles/material-ui/MaterialUIStyles';
import { useRecoilState, useRecoilValue } from 'recoil';
import TimeFilterSearchState, { TimeFilterOptions } from 'state/TimeFilterSearchState';
import { useParams } from 'react-router-dom';
import { CurrentOrgSelector } from 'state/OrgsForDropDownState';
import { hasScrolled } from 'utils/utils';
import { track } from '@amplitude/analytics-browser';

export default function SearchTimeFilter(): React.ReactElement {
  const { org = '' } = useParams();
  const [searchTime, setSearchTime] = useRecoilState(TimeFilterSearchState);

  const organization = useRecoilValue(CurrentOrgSelector(org));

  const handleChange = (event: SelectChangeEvent<typeof searchTime>) => {
    setSearchTime(event.target.value);
    track('filter org', {
      'filter type': 'time filter',
      'filter selection': 'time',
      'filter term': event.target.value,
      org: `${organization.name} | ${organization.id}`,
      scrolled: hasScrolled('kanban-actions'),
    });
  };

  return (
    <ThemeProvider theme={muiTheme}>
      <Select
        id="time-filter-select"
        data-testid="time-filter-select"
        value={searchTime}
        onChange={handleChange}
        displayEmpty
        sx={{ width: 200 }}
      >
        {(Object.keys(TimeFilterOptions) as (keyof typeof TimeFilterOptions)[]).map((key) => {
          return (
            <MenuItem key={key} value={TimeFilterOptions[key]}>
              {TimeFilterOptions[key]}
            </MenuItem>
          );
        })}
      </Select>
    </ThemeProvider>
  );
}
