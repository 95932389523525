import React, { useState } from 'react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findImages } from 'utils/utils';
import { LightTooltip } from 'styles/material-ui/MaterialUIStyles';
import Modal from 'common/Modal/DepricatedModal/Modal';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { useRecoilValue } from 'recoil';
import { CurrentOrgSelector } from 'state/OrgsForDropDownState';
import { useParams } from 'react-router-dom';
import { CurrentProductSelector } from 'state/ProductsForOrgState';
import { track } from '@amplitude/analytics-browser';

import './SectionImageGallery.scss';

interface Props {
  content: string;
  title: string;
  page: string;
}

export function SectionImageGallery({ content, title, page }: Props): React.ReactElement {
  const { productId = '', org = '' } = useParams();
  const currentProduct = useRecoilValue(CurrentProductSelector(productId));
  const organization = useRecoilValue(CurrentOrgSelector(org));
  const [galleryImages, setGalleryImages] = useState<ReactImageGalleryItem[]>([]);
  const [galleryIsOpen, setGalleryIsOpen] = useState<boolean>(false);
  const category = page === 'overview' ? 'product' : 'update';

  function printImageGalleryButton() {
    const imagesInContent = findImages(content);
    if (imagesInContent.length > 0)
      return (
        <LightTooltip
          title={'Click to view images in gallery view'}
          data-testid={'image-gallery-tooltip'}
          placement="top-start"
          arrow
        >
          <button
            data-testid="gallery-view"
            aria-label="enlarge image"
            className="gallery-view-button"
            onClick={() => {
              setGalleryIsOpen(true);
              setGalleryImages(imagesInContent);
              track('click to enlarge content image', {
                org: `${organization.name} | ${organization.id}`,
                product: `${currentProduct.name} | ${currentProduct.id}`,
                section: title,
                'app location': category,
              });
            }}
          >
            <FontAwesomeIcon icon={'image' as IconName} />
          </button>
        </LightTooltip>
      );
  }

  return (
    <>
      {galleryIsOpen && (
        <Modal
          title={title}
          isOpenModal={galleryIsOpen}
          closeHandler={() => setGalleryIsOpen(false)}
          showCloseIcon={true}
          className="gallery-modal"
        >
          <ImageGallery
            items={galleryImages}
            showPlayButton={false}
            showThumbnails={false}
            showBullets={true}
          />
        </Modal>
      )}
      {printImageGalleryButton()}
    </>
  );
}
