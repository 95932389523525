import { track } from '@amplitude/analytics-browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface Props {
  className: string;
  location: 'help' | 'footer';
}

export default function SupportDocsLink(props: Props) {
  const { className, location } = props;
  return (
    <a
      className={className}
      href="https://azureford.sharepoint.com/sites/fordlabsproductsuite/SitePages/Newsie.aspx"
      target="_blank"
      rel="noreferrer noopener"
      onClick={() => {
        track('click docs link', {
          'app location': location,
        });
      }}
    >
      <FontAwesomeIcon
        className="nav-link-icon icon fa-inverse"
        icon={['far', 'file-lines']}
        aria-hidden="true"
      />
      <span className="nav-link-text">{location === 'help' && 'View'} Support Docs</span>
    </a>
  );
}
