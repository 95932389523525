import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import IsContentChangedState from 'state/ContentChangedState';
import ErrorMessageState from 'state/ErrorMessageState';
import { CurrentOrgSelector } from 'state/OrgsForDropDownState';
import { CurrentProductSelector } from 'state/ProductsForOrgState';
import { extractErrorMessage } from 'utils/utils';
import { Actions, TrackUpdateAction } from '../UpdateTab';
import { track } from '@amplitude/analytics-browser';
import Page from 'types/Page';

import './UpdatePageButtons.scss';

export enum VisibleViewButtons {
  ADD_AND_EDIT,
  EDIT,
  NONE,
}

interface Props {
  action: Actions;
  setShowAddNewUpdateModal: (showAddNewUpdateModal: boolean) => void;
  trackClick: (action: TrackUpdateAction, week?: string, appLocation?: string) => void;
  setAction: (action: Actions) => void;
  setShowConfirmationModal: (showConfirmationModal: boolean) => void;
  disabled: boolean;
  savePage: () => Promise<Page>;
  visibleViewButtons: VisibleViewButtons;
}

export default function UpdatePageButtons({
  savePage,
  action,
  setShowAddNewUpdateModal,
  trackClick,
  setAction,
  setShowConfirmationModal,
  disabled,
  visibleViewButtons,
}: Props): React.ReactElement {
  const { productId = '', org = '' } = useParams();
  const organization = useRecoilValue(CurrentOrgSelector(org));
  const currentProduct = useRecoilValue(CurrentProductSelector(productId));
  const [errorMessage, setErrorMessage] = useRecoilState(ErrorMessageState);
  const setIsContentChanged = useSetRecoilState(IsContentChangedState);

  function saveUpdate() {
    trackClick(
      action === Actions.add
        ? TrackUpdateAction.SAVE_NEW_UPDATE
        : TrackUpdateAction.SAVE_EDITED_UPDATE,
    );
    savePage()
      .then(() => {
        if (action === Actions.add) {
          track(TrackUpdateAction.VIEW_UPDATE, {
            org: `${organization.name} | ${organization.id}`,
            archived: currentProduct.done ?? false,
            product: `${currentProduct.name} | ${currentProduct.id}`,
          });
        }
        setAction(Actions.view);
        setErrorMessage({ ...errorMessage, amplitudeErrorMessage: '' });
        setIsContentChanged(false);
      })
      .catch((e) => {
        setErrorMessage({
          ...errorMessage,
          amplitudeErrorMessage: extractErrorMessage(
            e,
            'Encountered an error while saving product update.',
          ),
        });
      });
  }

  function saveOrCancelButtons() {
    return (
      <>
        <button
          className={`button-blue ${disabled ? 'disabled' : ''}`}
          disabled={disabled}
          onClick={saveUpdate}
          title={`${disabled ? 'Saving Image(s) is in progress. Please Wait.' : ''}`}
        >
          Save Update
        </button>
        <button className="button-red" onClick={() => setShowConfirmationModal(true)}>
          Cancel
        </button>
      </>
    );
  }
  function handleEditButtonClick() {
    trackClick(TrackUpdateAction.START_EDIT_UPDATE);
    setAction(Actions.edit);
  }

  return (
    <>
      {action === Actions.view && (
        <div className={'update-buttons'}>
          {visibleViewButtons === VisibleViewButtons.ADD_AND_EDIT && (
            <button
              data-testid={`update-add-plus`}
              aria-label="add update"
              className="write-update-button"
              onClick={() => setShowAddNewUpdateModal(true)}
            >
              <FontAwesomeIcon icon={'plus' as IconName} className="fai-shadow plus" />
              <span>Add New</span>
            </button>
          )}
          {(visibleViewButtons === VisibleViewButtons.ADD_AND_EDIT ||
            visibleViewButtons === VisibleViewButtons.EDIT) && (
            <button
              data-testid={`update-edit-pencil`}
              aria-label="edit update"
              className="write-update-button"
              onClick={handleEditButtonClick}
            >
              <FontAwesomeIcon icon={'pen' as IconName} className="fai-shadow pen" />
              <span>Edit Update</span>
            </button>
          )}
        </div>
      )}
      {action !== Actions.view && (
        <div className="edit-update-buttons">{saveOrCancelButtons()}</div>
      )}
    </>
  );
}
