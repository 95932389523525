import React, { useCallback, useEffect, useState } from 'react';
import flagsmith from 'flagsmith';

import { FlagsmithContext } from './FlagsmithContext';

import type { PropsWithChildren } from 'react';
import type { IFlags } from 'flagsmith';

interface Props {
  api: string;
  userId?: string;
  defaultFlags?: IFlags;
}

interface ConfigProps {
  environmentID: string;
  api: string;
  userId?: string;
  defaultFlags?: IFlags;
}

export function FlagsmithProvider({
  userId,
  children,
  api,
  defaultFlags,
}: PropsWithChildren<Props>): React.ReactElement {
  const [flags, setFlags] = useState<IFlags>({});

  const init = useCallback(
    async (config: ConfigProps) => {
      await flagsmith
        .init({ ...config, onChange: setFlags })
        .catch((error) => console.error('Could not initialize Flagsmith: ', error));
    },
    [setFlags],
  );

  useEffect(() => {
    if (userId) {
      flagsmith.identify(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (process.env.REACT_APP_FLAGSMITH_ENV_ID) {
      init({ environmentID: process.env.REACT_APP_FLAGSMITH_ENV_ID, api, defaultFlags });
    }
  }, [api, defaultFlags]);

  return <FlagsmithContext.Provider value={flags}>{children}</FlagsmithContext.Provider>;
}
