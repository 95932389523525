import React, { FormEvent, useState } from 'react';
import { TextField } from '@mui/material';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { ModalContentsState } from 'state/ModalContentsState';
import { CurrentOrgSelector } from 'state/OrgsForDropDownState';
import OrgService from 'services/api/OrgService';

import styles from './EditOverviewModal.module.scss';
import { track } from '@amplitude/analytics-browser';

interface Props {
  orgParam: string;
}

export default function EditOverviewModal({ orgParam }: Props) {
  const setModalContents = useSetRecoilState(ModalContentsState);
  const [organization, setOrganization] = useRecoilState(CurrentOrgSelector(orgParam));

  const MAX_DESC_LENGTH = 250;
  const [description, setDescription] = useState<string>(organization.blurb);
  const [orgName, setOrgName] = useState<string>(organization.name);
  const [icon, setIcon] = useState<string>(organization.icon);

  function eventsToSend(): string[] {
    const events = [];
    if (orgName !== organization.name) events.push('name');
    if (description !== organization.blurb) events.push('description');
    if (icon !== organization.icon) events.push('icon');
    return events;
  }

  function closeModal() {
    setModalContents(null);
  }

  function onSubmit(event: FormEvent) {
    event.preventDefault();

    const requiredFieldsArePopulated = description && orgName && icon;
    if (requiredFieldsArePopulated) {
      OrgService.updateOrgOverview(organization.id, {
        name: orgName,
        blurb: description,
        icon,
      })
        .then(() => {
          setOrganization({ ...organization, name: orgName, blurb: description, icon });

          eventsToSend().forEach((eventName) => {
            track('edit org overview', {
              org: orgName,
              section: eventName,
            });
          });

          closeModal();
        })
        .catch((event) =>
          track('view error', {
            org: organization.name,
            error: event.message,
            'app location': 'edit org overview',
          }),
        );
    }
  }

  return (
    <form className={styles.editOverviewModal} onSubmit={onSubmit}>
      <h5 className={styles.title}>Edit Overview</h5>
      <div className={styles.inputFields}>
        <TextField
          label="Org Name"
          value={orgName}
          onChange={(event) => setOrgName(event.target.value)}
          inputProps={{ title: 'Enter organization name' }}
          required={true}
          fullWidth={true}
        />
        <TextField
          label="Org Description"
          value={description}
          onChange={(event) => {
            const desc = event.target.value;
            if (desc.length <= MAX_DESC_LENGTH) setDescription(desc);
          }}
          multiline
          rows={4}
          required={true}
          fullWidth={true}
          className={styles.textField}
          helperText={
            <span>
              {description.length}/{MAX_DESC_LENGTH} characters
            </span>
          }
          inputProps={{ title: 'Enter organization description' }}
        />
        <TextField
          label="Org Icon"
          value={icon}
          onChange={(event) => setIcon(event.target.value)}
          required={true}
          fullWidth={true}
          className={styles.textField}
          helperText={
            <>
              <a
                href="https://fontawesome.com/v6/search?m=free&s=solid"
                target="_blank"
                rel="noopener noreferrer"
              >
                View icon library
              </a>{' '}
              and add icon’s name to use it for the org
            </>
          }
          inputProps={{ title: 'Enter icon name' }}
        />
      </div>
      <div className={styles.buttonContainer}>
        <button className="button-blue-dialog" onClick={closeModal}>
          Cancel
        </button>
        <button type="submit" className="button-blue">
          Save
        </button>
      </div>
    </form>
  );
}
