import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { track } from '@amplitude/analytics-browser';

function NoLeadsAddedDisplay() {
  const { org = '' } = useParams();

  function onClick() {
    track('click "your org admin" link', {
      'app location': 'line',
    });
  }

  return (
    <div>
      <b>No team leads added</b> <br />
      <span>
        Contact{' '}
        <Link to={`/${org}/details`} onClick={onClick}>
          your org admin
        </Link>{' '}
        to add a team lead.
      </span>
    </div>
  );
}

export default NoLeadsAddedDisplay;
