import { useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ActiveTabState } from 'state/ActiveTabState';
import { CurrentOrgSelector } from 'state/OrgsForDropDownState';
import useScreenSize from './useScreenSize';
import { track } from '@amplitude/analytics-browser';
import getActiveTab, { ActiveLineTab, ActiveTab } from '../utils/getActiveTab';

interface InputProps {
  type: string;
  typeNameId: string;
}

interface OutputProps {
  activeTab: ActiveTab | ActiveLineTab;
  setActiveTab: (activeTab: ActiveTab) => void;
}

function useActiveTab({ type, typeNameId }: InputProps): OutputProps {
  const initialRender = useRef(true);
  const { org = '' } = useParams();
  const [activeTab, setActiveTab] = useRecoilState(ActiveTabState);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isDesktop = useScreenSize();
  const organization = useRecoilValue(CurrentOrgSelector(org));

  const isProduct = type.toLowerCase() === 'product';

  function getTabForPage() {
    return isProduct ? ActiveTab : ActiveLineTab;
  }

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      const tabName = getTabForPage()[activeTab].toLowerCase();
      if (!pathname.includes(tabName)) {
        navigate(`../${tabName}`);
        track('switch tabs', {
          org: `${organization.name} | ${organization.id}`,
          [type]: typeNameId,
          'navigation-level': `${type} ${
            isProduct && tabName === 'profile' ? 'overview' : tabName
          }`,
        });
      }
    }
  }, [activeTab]);

  useEffect(() => {
    if (!pathname.includes(getTabForPage()[activeTab].toLowerCase())) {
      setActiveTab(getActiveTab(pathname, isProduct));
    }
  }, [pathname]);

  useEffect(() => {
    if (isDesktop && activeTab === getTabForPage().INFO) {
      setActiveTab(getTabForPage().PROFILE.valueOf());
    }
  }, [isDesktop]);

  return { activeTab, setActiveTab };
}

export default useActiveTab;
