import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { useRecoilValue } from 'recoil';
import { OrgDetailsState } from 'state/OrgDetailsState';
import { CDSIDFromTokenSelector } from 'state/AccessTokenState';
import { track } from '@amplitude/analytics-browser';
import ProductHierarchy, { HierarchyType } from 'types/ProductHierarchy';
import { ModalAction } from '../OrgDetailsEditModal/OrgDetailsEditModal';

interface Props {
  setModalAction: (modalAction: ModalAction) => void;
  setProductHierarchyModalState: (productHierarchy: ProductHierarchy) => void;
  groupsLength: number;
}

export default function AddGroupButton(props: Props) {
  const { setModalAction, setProductHierarchyModalState, groupsLength } = props;

  const orgDetails = useRecoilValue(OrgDetailsState);
  const cdsid = useRecoilValue(CDSIDFromTokenSelector);
  const orgId = orgDetails.id;
  const org = `${orgDetails.name} | ${orgId}`;

  return (
    <button
      className="button-add"
      onClick={() => {
        track('add new group', { org, cdsid });
        setModalAction(ModalAction.ADD);
        setProductHierarchyModalState({
          name: '',
          orgId: orgId,
          type: HierarchyType.GROUP,
          order: groupsLength,
          teamMembers: null,
        } as ProductHierarchy);
      }}
    >
      <FontAwesomeIcon icon={'plus' as IconName} />
      Add New Group
    </button>
  );
}
