import React, { SyntheticEvent, useState } from 'react';
import { TextField, ThemeProvider } from '@mui/material';
import Modal from 'common/Modal/DepricatedModal/Modal';
import { muiTheme } from 'styles/material-ui/MaterialUIStyles';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  ProductHierarchyByIdInState,
  ProductHierarchyByTypeInState,
} from 'state/ProductHierarchyState';
import { UnsavedChanges } from 'common/UnsavedChanges/UnsavedChanges';
import { usePrompt } from 'hooks/usePrompt';
import { ActionSpec, AmplitudeTracking } from 'data/commonTypes';
import { OrgDetailsState } from 'state/OrgDetailsState';
import { extractErrorMessage, toTitleCase } from 'utils/utils';
import ProductGroupSelect from 'common/ProductGroupSelect/ProductGroupSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { track } from '@amplitude/analytics-browser';
import ProductHierarchy, {
  AddProductHierarchyRequest,
  HierarchyType,
} from 'types/ProductHierarchy';
import ProductHierarchyService from 'services/api/ProductHierarchyService';

export enum ModalAction {
  ADD,
  EDIT,
}

interface Props {
  productHierarchyModalState: ProductHierarchy;
  setProductHierarchyModalState: (productHierarchy: ProductHierarchy | undefined) => void;
  setExpanded: (index: number) => void;
  modalAction: ModalAction;
  expanded: number;
}

enum ModalType {
  EDITGROUP,
  UNSAVEDCHANGES,
  SAVEFAILED,
  EDITLINE,
  ADDGROUP,
  ADDLINE,
}

function getModalSpec(modalType: ModalType): ActionSpec {
  switch (modalType) {
    case ModalType.UNSAVEDCHANGES:
      return { key: modalType, title: 'Unsaved Changes!', showCloseIcon: false };
    case ModalType.SAVEFAILED:
      return { key: modalType, title: 'Save Failed', showCloseIcon: true };
    case ModalType.EDITLINE:
      return { key: modalType, title: 'Edit Line', showCloseIcon: true };
    case ModalType.EDITGROUP:
      return { key: modalType, title: 'Edit Group', showCloseIcon: true };
    case ModalType.ADDGROUP:
      return {
        key: modalType,
        title: 'Add New Group',
        subtitle:
          'This new Group and its associated Lines will not appear on the org page until a product is added to the Lines.',
        showCloseIcon: true,
      };
    case ModalType.ADDLINE:
      return {
        key: modalType,
        title: 'Add New Line',
        showCloseIcon: true,
        subtitle:
          'This new Line will not appear on the org page until after a product has been added to the Line.',
      };
  }
}

export function OrgDetailsEditModal({
  productHierarchyModalState,
  setProductHierarchyModalState,
  setExpanded,
  modalAction,
  expanded,
}: Props): React.ReactElement {
  const setProductHierarchyInState = useSetRecoilState(
    ProductHierarchyByIdInState(productHierarchyModalState.id),
  );
  const orgDetails = useRecoilValue(OrgDetailsState);
  const org = `${orgDetails.name} | ${orgDetails.id}`;
  const listOfGroups = useRecoilValue(ProductHierarchyByTypeInState(HierarchyType.GROUP));
  const [productHierarchy, setProductHierarchy] = useState<ProductHierarchy>({
    ...productHierarchyModalState,
  });

  const [showError, setShowError] = useState<boolean>(false);
  const [action, setAction] = useState<ActionSpec>(getActionSpecification());
  const [lines, setLines] = useState<Map<string, string>>(new Map([['line0', '']]));
  const [uniqueIndex, setUniqueIndex] = useState<number>(1);
  const [duplicateSet, setDuplicateSet] = useState<Set<string>>(new Set());

  function getActionSpecification(): ActionSpec {
    if (modalAction === ModalAction.ADD) {
      return getModalSpec(
        productHierarchyModalState.type === HierarchyType.GROUP
          ? ModalType.ADDGROUP
          : ModalType.ADDLINE,
      );
    } else {
      return getModalSpec(
        productHierarchyModalState.type === HierarchyType.GROUP
          ? ModalType.EDITGROUP
          : ModalType.EDITLINE,
      );
    }
  }
  const isNameChanged = () => {
    return productHierarchy.name !== productHierarchyModalState.name;
  };
  const isGroupChanged = () => {
    return productHierarchy.parentId !== productHierarchyModalState.parentId;
  };

  usePrompt(
    'There are unsaved changes that will be lost. Are you sure you want to leave?',
    isNameChanged(),
  );

  const handleDialogClose = () => {
    if (isNameChanged() && action.key !== ModalType.SAVEFAILED) {
      setAction(getModalSpec(ModalType.UNSAVEDCHANGES));
    } else setProductHierarchyModalState(undefined);
  };

  const amplitudeEditEvent: AmplitudeTracking = {
    eventName: `save productHierarchy edit`,
    data: {
      org: `${orgDetails.name} | ${orgDetails.id}`,
      [productHierarchy.type.toLocaleLowerCase()]: `${productHierarchyModalState.name} | ${productHierarchyModalState.id}`,
      ...(isNameChanged() && { 'new name': productHierarchy.name }),
      ...(isGroupChanged() && { 'new assigned group': productHierarchy.parentId }),
      duplicate: false,
    },
  };

  function generateAddRequest(): AddProductHierarchyRequest {
    const request: AddProductHierarchyRequest = { orgId: orgDetails.id, lines: [] };

    const lineHierarchies: ProductHierarchy[] = [];
    lines.forEach((name) => {
      lineHierarchies.push({
        name: name,
        orgId: orgDetails.id,
        type: HierarchyType.LINE,
      } as ProductHierarchy);
    });

    request.group = productHierarchy;
    request.lines = lineHierarchies;

    return request;
  }

  async function saveProductHierarchy(): Promise<ProductHierarchy[]> {
    switch (modalAction) {
      case ModalAction.ADD:
        return ProductHierarchyService.addProductHierarchy(generateAddRequest());
      case ModalAction.EDIT:
        return ProductHierarchyService.editProductHierarchy(productHierarchy).then((res) => [
          res.data,
        ]);
    }
  }

  const changeToEditMode = () => {
    setAction(getActionSpecification());
  };

  function mutateLines(key: string, newValue?: string) {
    const newLines = new Map<string, string>([...lines]);

    if (newValue === undefined) {
      newLines.delete(key);
    } else {
      newLines.set(key, newValue);
    }

    setLines(newLines);
  }

  function addGroup(): React.ReactElement {
    const textBoxes: React.ReactElement[] = [];

    lines.forEach((_, key) => {
      textBoxes.push(lineNameTextBox(key));
    });

    return <>{textBoxes}</>;
  }

  function printContent(): React.ReactElement {
    switch (action.key) {
      case ModalType.UNSAVEDCHANGES:
        return printUnsavedChanges();
      case ModalType.SAVEFAILED:
        return printSaveFailed();
      default:
        return printFormContent();
    }
  }

  function printUnsavedChanges(): React.ReactElement {
    return (
      <UnsavedChanges
        continueHandler={changeToEditMode}
        exitHandler={() => setProductHierarchyModalState(undefined)}
      />
    );
  }

  function printSaveFailed(): React.ReactElement {
    return (
      <>
        <form
          id="modal-action-message"
          onSubmit={(e) => {
            e.preventDefault();
            changeToEditMode();
          }}
        >
          <div style={{ textAlign: 'center' }}>
            Your save was not successful. Please try again. If the issue persists, please reach out
            to Newsie Support.
          </div>
          <div className="modal-button-container">
            <button className={'button-blue'} type="submit">
              Try Again
            </button>
          </div>
        </form>
      </>
    );
  }

  function printFormContent(): React.ReactElement {
    return (
      <form
        className="dialog-form"
        onSubmit={(e) => {
          e.preventDefault();

          const duplicates = findDuplicateValues(lines);
          setDuplicateSet(duplicates);

          if (duplicates.size < 1) {
            handleSave();
          }
        }}
      >
        <div className="user-input-container">
          {nameTextBox()}

          {action.key === ModalType.ADDGROUP && addGroup()}
          {(action.key === ModalType.EDITLINE || action.key === ModalType.ADDLINE) && (
            <div className="product-hierarchy group-select">
              <ProductGroupSelect
                handleChange={(event) => {
                  setProductHierarchy({ ...productHierarchy, parentId: event.target.value });
                }}
                selectedProductGroupId={productHierarchy.parentId ?? ''}
                listOfGroups={listOfGroups}
                disabled={action.key === ModalType.ADDLINE}
              />
            </div>
          )}
        </div>

        {action.key === ModalType.ADDGROUP && circlePlusButton()}

        <button className="button-blue" data-testid="save-producthierarchy-edit" type="submit">
          {action.key === ModalType.ADDLINE || action.key === ModalType.ADDGROUP
            ? getModalSpec(action.key).title.replace(' New', '')
            : 'Save'}
        </button>
      </form>
    );
  }

  const handleSave = () => {
    productHierarchy.name = productHierarchy.name.trim();

    if (productHierarchy.name !== '' && (isNameChanged() || isGroupChanged())) {
      saveProductHierarchy()
        .then((response) => {
          setProductHierarchyModalState(undefined);

          response.forEach((document) => {
            setProductHierarchyInState(document);
          });
          if (modalAction === ModalAction.EDIT)
            track(amplitudeEditEvent.eventName, amplitudeEditEvent.data);
          let index = response[0].order ?? expanded;
          if (isGroupChanged()) {
            index = listOfGroups.findIndex((group) => productHierarchy.parentId === group.id);
          }
          setExpanded(index);
        })
        .catch((error) => handleError(error));
    } else {
      setProductHierarchyModalState(undefined);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleError = (error: any) => {
    if (error.response && error.response.status === 409) {
      setShowError(true);
      amplitudeEditEvent.data.duplicate = true;
      track(amplitudeEditEvent.eventName, amplitudeEditEvent.data);
    } else {
      const action = toTitleCase(ModalAction[modalAction]);
      track('view error', {
        org: org,
        [productHierarchy.type.toLocaleLowerCase()]: `${productHierarchyModalState.name} | ${productHierarchyModalState.id}`,
        error: extractErrorMessage(error, `Product Hierarchy ${action} failed` + error),
        'app location': `save ${action.toLowerCase()}ed ${productHierarchy.type.toLocaleLowerCase()}`,
      });
      setAction(getModalSpec(ModalType.SAVEFAILED));
    }
  };

  function trashCanButton(name: string): React.ReactNode {
    const handleDeleteLine = (event: SyntheticEvent, name: string) => {
      event.preventDefault();
      mutateLines(name);
    };

    return (
      <button
        data-testid={name + '-delete'}
        onClick={(event: SyntheticEvent) => handleDeleteLine(event, name)}
        aria-label={'delete item'}
      >
        <FontAwesomeIcon icon={'trash-can' as IconName} />
      </button>
    );
  }

  function circlePlusButton(): React.ReactNode {
    const handleAddLine = (event: SyntheticEvent) => {
      event.preventDefault();

      mutateLines('line' + uniqueIndex, '');
      setUniqueIndex(uniqueIndex + 1);
    };

    return (
      <button onClick={handleAddLine} className={'add-line-modal-button'}>
        <FontAwesomeIcon icon={'circle-plus' as IconName} />
        &nbsp;Add Another Line
      </button>
    );
  }

  function lineNameTextBox(name = 'name-text-edit'): React.ReactElement {
    const handleLineNameChange = ({
      target: { name, value },
    }: React.ChangeEvent<HTMLInputElement>) => {
      mutateLines(name, value);
    };

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const value = lines.get(name)!;
    const isDuplicate = duplicateSet?.has(value) && value !== '';
    const showTrashCan = lines.size > 1;
    const errorMessage = isDuplicate ? 'There is already a Line with the same name' : '';

    return textBoxHelper(name, 'Line', handleLineNameChange, value, showTrashCan, errorMessage);
  }

  function nameTextBox(name = 'name-text-edit'): React.ReactElement {
    const handleMainNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setProductHierarchy({ ...productHierarchy, name: event.target.value });
      if (showError) setShowError(false);
    };

    const displayType = toTitleCase(productHierarchy.type);
    const errorMessage =
      `There is already a ${displayType} with the same name` +
      (productHierarchy.type === HierarchyType.LINE ? ' in the Group' : '');

    return textBoxHelper(
      name,
      displayType,
      handleMainNameChange,
      productHierarchy.name,
      false,
      showError ? errorMessage : '',
    );
  }

  return (
    <ThemeProvider theme={muiTheme}>
      <Modal
        title={action.title}
        isOpenModal={true}
        closeHandler={handleDialogClose}
        showCloseIcon={action.showCloseIcon}
        subtitle={action.subtitle}
      >
        {printContent()}
      </Modal>
    </ThemeProvider>
  );

  function textBoxHelper(
    name: string,
    displayType: string,
    changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void,
    value: string,
    showTrashCan: boolean,
    errorText = '',
  ): React.ReactElement {
    return (
      <div className="dialog-input-wrapper" key={name + '-div'}>
        {/*TODO NOT THIS*/}
        <div className={'space-holder'} />
        <TextField
          error={errorText !== ''}
          helperText={errorText}
          fullWidth={true}
          onChange={changeHandler}
          id={'productHierarchy-id'}
          label={`${displayType} Name`}
          variant="outlined"
          required={true}
          name={name}
          defaultValue={value}
          inputProps={{
            'data-testid': name,
            title: `Enter ${displayType} Name`,
          }}
        />

        <div className={'trash-can-button space-holder'}>
          {showTrashCan && trashCanButton(name)}
        </div>
      </div>
    );
  }
}

function findDuplicateValues(lines: Map<string, string>) {
  const countMap: Map<string, number> = new Map();

  lines.forEach((name) => {
    const count = countMap.get(name) ?? 0;
    countMap.set(name, count + 1);
  });

  const duplicateSet: Set<string> = new Set();
  countMap.forEach((count, name) => {
    if (count > 1) duplicateSet.add(name);
  });

  return duplicateSet;
}
