import { atom } from 'recoil';

type ErrorMessage = {
  amplitudeErrorMessage: string;
  errorMessage: string;
};

const ErrorMessageState = atom<ErrorMessage>({
  key: 'ErrorMessageState',
  default: {
    amplitudeErrorMessage: '',
    errorMessage: 'There was an error saving the changes. Please try saving again.',
  },
});

export default ErrorMessageState;
