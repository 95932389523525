import React from 'react';
import useProductHierarchy from 'hooks/useProductHierarchy';
import LoadingMessage from 'common/LoadingMessage/LoadingMessage';
import ErrorMessageView from 'common/ErrorMessageView/ErrorMessageView';

import GroupsAndLinesCardContent from './GroupsAndLinesCardContent/GroupsAndLinesCardContent';

interface Props {
  hasEditOrgPermission: boolean;
}

export default function GroupsAndLinesCard({ hasEditOrgPermission }: Props): React.ReactElement {
  const { productHierarchy } = useProductHierarchy();

  switch (productHierarchy.state) {
    case 'Loaded':
      return (
        <GroupsAndLinesCardContent
          productHierarchies={productHierarchy.productHierarchy}
          hasEditOrgPermission={hasEditOrgPermission}
        />
      );
    case 'Error':
      return <ErrorMessageView error={productHierarchy.message} componentName="Edit Org" />;
    case 'Loading':
    default:
      return <LoadingMessage />;
  }
}
