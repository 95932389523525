import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { track } from '@amplitude/analytics-browser';

interface Props {
  orgName: string;
  orgDescription?: string;
  orgLink: string;
  orgIcon?: string | null;
  orgId: string;
  location: string;
}

export default function OrgCard({
  orgName,
  orgDescription,
  orgLink,
  orgIcon,
  orgId,
  location,
}: Props): ReactElement {
  function trackClick() {
    track('navigate orgs', {
      'app location': location,
      org: `${orgName} | ${orgId}`,
    });
  }
  return (
    <div className={'org-card'}>
      <Link
        className="org-card-header"
        data-testid={`${orgName}-card-heading`}
        to={orgLink}
        onClick={() => trackClick()}
      >
        <FontAwesomeIcon
          className="fa-2x"
          icon={orgIcon as IconName}
          data-testid={orgName + '-icon'}
        ></FontAwesomeIcon>

        <span data-testid="org-card-header">{orgName}</span>
      </Link>
      <p id="all-orgs" className="desktop-only">
        {orgDescription}
      </p>
      <Link
        to={orgLink}
        data-testid={`${orgLink.substring(1, orgLink.length)}-view-products`}
        onClick={() => trackClick()}
      >
        View products {'>>'}
      </Link>
    </div>
  );
}
