import DragAndDrop from './DragAndDrop/DragAndDrop';
import { Droppable } from 'react-beautiful-dnd';
import React, { Fragment } from 'react';
import AddGroupButton from '../AddGroupButton/AddGroupButton';
import DoneButton from '../DoneButton/DoneButton';
import DraggableItem from './DraggableItem/DraggableItem';
import { track } from '@amplitude/analytics-browser';
import { useRecoilValue } from 'recoil';
import { OrgDetailsState } from 'state/OrgDetailsState';
import ProductHierarchy, { HierarchyType } from 'types/ProductHierarchy';
import { ModalAction } from '../OrgDetailsEditModal/OrgDetailsEditModal';

interface Props {
  expanded: number;
  setExpanded: (expandedIndex: number) => void;
  isEditMode: boolean;
  productHierarchies: ProductHierarchy[];
  setModalAction: (modalAction: ModalAction) => void;
  setProductHierarchyModalState: (productHierarchy: ProductHierarchy | undefined) => void;
  handleEditModeToggle: () => void;
}

export default function DragAndDropSection(props: Props) {
  const {
    expanded,
    setExpanded,
    isEditMode,
    productHierarchies,
    setModalAction,
    setProductHierarchyModalState,
    handleEditModeToggle,
  } = props;

  const orgDetails = useRecoilValue(OrgDetailsState);
  const org = `${orgDetails.name} | ${orgDetails.id}`;

  const lines = productHierarchies.filter(
    (ph: { type: HierarchyType }) => ph.type === HierarchyType.LINE,
  );
  const groups = productHierarchies.filter((ph: { id: string; type: HierarchyType }) => {
    return ph.type === HierarchyType.GROUP;
  });

  const editProductHierarchyHandler = (productHierarchy: ProductHierarchy | undefined) => {
    if (productHierarchy) {
      track('start productHierarchy edit', {
        org: org,
        type: `${productHierarchy.type.toLowerCase()}`,
        name: `${productHierarchy.name}`,
      });
    }
    setModalAction(ModalAction.EDIT);
    setProductHierarchyModalState(productHierarchy);
  };

  return (
    <DragAndDrop groups={groups} setExpanded={setExpanded}>
      <Droppable droppableId={'group'}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <div className="box-info">
              {groups.map((group: ProductHierarchy, index: number) => (
                <Fragment key={`${group.name}-${index}`}>
                  <DraggableItem
                    group={group}
                    index={index}
                    isEditMode={isEditMode}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    lines={lines}
                    editProductHierarchyHandler={editProductHierarchyHandler}
                  />
                </Fragment>
              ))}
              {isEditMode && (
                <>
                  <AddGroupButton
                    setModalAction={setModalAction}
                    setProductHierarchyModalState={setProductHierarchyModalState}
                    groupsLength={groups.length}
                  />
                  <DoneButton onClick={handleEditModeToggle} />
                </>
              )}
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragAndDrop>
  );
}
