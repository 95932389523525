import React, { useState } from 'react';
import { ModalAction, OrgDetailsEditModal } from './OrgDetailsEditModal/OrgDetailsEditModal';
import { useRecoilValue } from 'recoil';
import { OrgDetailsState } from 'state/OrgDetailsState';
import { track } from '@amplitude/analytics-browser';
import DragAndDropSection from './DragAndDropSection/DragAndDropSection';
import ProductHierarchy from 'types/ProductHierarchy';
import IconButton from 'common/IconButton/IconButton';

interface Props {
  productHierarchies: ProductHierarchy[];
  hasEditOrgPermission: boolean;
}

export default function GroupsAndLinesCardContent(props: Props): React.ReactElement {
  const { productHierarchies, hasEditOrgPermission } = props;

  const orgDetails = useRecoilValue(OrgDetailsState);

  const [modalAction, setModalAction] = useState<ModalAction>(ModalAction.EDIT);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [productHierarchyModal, setProductHierarchyModal] = useState<
    ProductHierarchy | undefined
  >();
  const [expanded, setExpanded] = useState<number>(0);

  const handleEditModeToggle = () => {
    if (!isEditMode) {
      track('open org edit mode', {
        org: `${orgDetails.name} | ${orgDetails.id}`,
        section: 'groups & lines',
      });
    }
    setIsEditMode(!isEditMode);
  };

  return (
    <>
      <div className={`details-box ${isEditMode ? 'edit-mode' : ''}`}>
        <div className="box-header">
          Groups & Lines
          {hasEditOrgPermission && (
            <IconButton
              iconName={isEditMode ? 'x' : 'pen'}
              testId="groups-lines-edit-toggle"
              ariaLabel="edit groups and lines"
              onClick={handleEditModeToggle}
              className={isEditMode ? 'edit-toggle-cancel' : 'edit-toggle-pencil'}
            />
          )}
        </div>
        <DragAndDropSection
          expanded={expanded}
          setExpanded={setExpanded}
          isEditMode={isEditMode}
          productHierarchies={productHierarchies}
          setModalAction={setModalAction}
          setProductHierarchyModalState={setProductHierarchyModal}
          handleEditModeToggle={handleEditModeToggle}
        />
      </div>
      {productHierarchyModal && (
        <OrgDetailsEditModal
          productHierarchyModalState={productHierarchyModal}
          setProductHierarchyModalState={setProductHierarchyModal}
          setExpanded={setExpanded}
          modalAction={modalAction}
          expanded={expanded}
        />
      )}
    </>
  );
}
