import { filterOrgListByFlagsmithList } from 'utils/utils';
import DashDivider from 'common/DashDivider/DashDivider';
import OrgCard from 'common/OrgCard/OrgCard';
import { Link } from 'react-router-dom';
import { track } from '@amplitude/analytics-browser';
import React from 'react';
import { useRemoteConfig } from 'react-flagsmith/useRemoteConfig';
import OrgForDropdown from 'types/OrgForDropdown';

import styles from './AllOrgsList.module.scss';

interface Props {
  orgs: OrgForDropdown[];
}

export default function AllOrgsList({ orgs }: Props) {
  const flagsmithEnabledExploreOrgs = useRemoteConfig<string[] | string>('featured_orgs_list');

  const filteredOrgsList: OrgForDropdown[] = filterOrgListByFlagsmithList(
    flagsmithEnabledExploreOrgs,
    orgs,
  );

  return (
    <div className="browse-orgs">
      <span className="company-name">FORD</span>
      <h2>Browse organizations</h2>
      <DashDivider />
      <div className="org-list">
        {filteredOrgsList.map((org) => {
          return (
            <OrgCard
              key={org.name}
              orgName={org.name}
              orgIcon={org.icon}
              orgDescription={org.blurb}
              orgLink={'/' + org.url}
              orgId={org.id}
              location={'browse'}
            />
          );
        })}
      </div>
      <Link
        to="/all"
        className={`button-blue ${styles.viewAllOrgsLink}`}
        data-testid="view-all-orgs"
        onClick={() =>
          track('navigate orgs', {
            'app location': 'browse view all orgs',
          })
        }
      >
        View All Organizations
      </Link>
    </div>
  );
}
