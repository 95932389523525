import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import LoadingMessage from '../../LoadingMessage/LoadingMessage';
import OrgForDropdown from 'types/OrgForDropdown';
import { filterOrgListByFlagsmithList } from 'utils/utils';
import Link from '../../Link/Link';
import TimeFilterSearchState, { TimeFilterOptions } from 'state/TimeFilterSearchState';
import { track } from '@amplitude/analytics-browser';
import useScreenSize from 'hooks/useScreenSize';
import { useRemoteConfig } from 'react-flagsmith/useRemoteConfig';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { OrgsForDropDownState } from 'state/OrgsForDropDownState';
import TextToSearchState from 'state/TextToSearchState';

interface Props {
  toggleDropdown: (location: string) => void;
}

export default function ExploreDropdown(props: Props) {
  const { toggleDropdown } = props;

  const orgsForDropDown = useRecoilValue(OrgsForDropDownState);
  const setTextToSearch = useSetRecoilState(TextToSearchState);
  const setSearchTime = useSetRecoilState(TimeFilterSearchState);

  const isDesktop = useScreenSize();

  const flagsmithEnabledExploreOrgs = isDesktop
    ? useRemoteConfig<string[] | string>('featured_orgs_list')
    : useRemoteConfig<string[] | string>('featured_orgs_list_mobile');

  function trackClick() {
    track('navigate orgs', {
      'app location': 'explore view all orgs',
    });
  }

  function getOrgLinks(orgs: OrgForDropdown[]): React.ReactElement[] {
    const filteredOrgsList: OrgForDropdown[] = filterOrgListByFlagsmithList(
      flagsmithEnabledExploreOrgs,
      orgs,
    );
    return filteredOrgsList.map((org) => {
      return (
        <Link
          key={org.id}
          className="nav-link menu"
          to={'/' + org.url}
          onClick={() => {
            setTextToSearch('');
            setSearchTime(TimeFilterOptions.UPDATED_ANY_TIME);
            track('navigate orgs', { org: `${org.name} | ${org.id}`, 'app location': 'explore' });
            toggleDropdown('explore');
          }}
        >
          <FontAwesomeIcon className="nav-link-icon" icon={org.icon as IconName} />
          <span className="nav-link-text">{org.name}</span>
        </Link>
      );
    });
  }

  function loadOrgs() {
    switch (orgsForDropDown.state) {
      case 'Loading':
        return <LoadingMessage />;
      case 'Loaded':
        return getOrgLinks(orgsForDropDown.organizations);
      case 'Error':
        return <span>{orgsForDropDown.message}</span>;
    }
  }

  return (
    <>
      <div className="nav-dropdown-container">
        <div className="nav-dropdown-content">
          {loadOrgs()}
          <Link
            to="/all"
            id="all-orgs-link"
            className="desktop-only"
            data-testid={`view-all-orgs`}
            onClick={() => {
              trackClick();
              toggleDropdown('explore');
            }}
          >
            View All Organizations
            <FontAwesomeIcon
              id="all-orgs-link-arrow"
              className="nav-link-icon"
              icon={'angle-right' as IconName}
              aria-hidden="true"
            />
          </Link>
        </div>
        <Link
          to="/all"
          className="all-orgs-button button-blue mobile-only"
          data-testid={`view-all-orgs-mobile`}
          onClick={() => {
            trackClick();
            toggleDropdown('explore');
          }}
        >
          View All Organizations
        </Link>
      </div>
      <div
        id="nav-dropdown-overlay"
        onClick={() => toggleDropdown('explore')}
        onTouchMove={() => toggleDropdown('explore')}
      ></div>
    </>
  );
}
