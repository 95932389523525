import ittyBittyInfograph from 'images/itty-bitty-infograph.png';
import { SelectChangeEvent } from '@mui/material';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  ProductHierarchyByIdInState,
  ProductHierarchyByTypeInState,
} from 'state/ProductHierarchyState';
import ProductLineDropDown from './ProductLineDropdown/ProductLineDropDown';
import ProductGroupSelect from 'common/ProductGroupSelect/ProductGroupSelect';
import TextFieldTooltip from 'common/TextFieldTooltip/TextFieldTooltip';
import { track } from '@amplitude/analytics-browser';
import OrgForKanban from 'types/OrgForKanban';
import ProductHierarchy, { HierarchyType } from 'types/ProductHierarchy';
import Product from 'types/Product';

import './ProductHierarchyInput.scss';

interface Props {
  location: string;
  product: Product;
  setProduct: (product: Product) => void;
  orgDetails: OrgForKanban;
}

export default function ProductHierarchyInput({
  location,
  product,
  setProduct,
  orgDetails,
}: Props): React.ReactElement {
  const listOfGroups = useRecoilValue(ProductHierarchyByTypeInState(HierarchyType.GROUP));
  const productLine = useRecoilValue(ProductHierarchyByIdInState(product.productLineId));
  const [selectedProductGroupId, setSelectedProductGroupId] = useState<string>(
    initProductGroup(productLine),
  );

  function initProductGroup(line: ProductHierarchy | undefined): string {
    return line ? listOfGroups.find((group) => group.id === line.parentId)?.id ?? '' : '';
  }

  function kanbanInfoIconHover() {
    track(
      `view taxonomy info ${
        location === 'add product form' ? 'on add new product form' : 'in edit taxonomy modal'
      }`,
      { org: `${orgDetails.name} | ${orgDetails.id}` },
    );
  }

  const handleChange = (event: SelectChangeEvent<typeof selectedProductGroupId>) => {
    setSelectedProductGroupId(event.target.value);
  };

  return (
    <div className="product-hierarchy">
      <ProductGroupSelect
        handleChange={handleChange}
        selectedProductGroupId={selectedProductGroupId}
        listOfGroups={listOfGroups}
      />
      <TextFieldTooltip
        dataTestid="info-icon"
        className="product-hierarchy-tooltip"
        onMouseEnter={kanbanInfoIconHover}
      />
      <img
        className="kanban-info-image"
        data-testid="kanban-info-image"
        src={ittyBittyInfograph}
        alt=""
      />
      <ProductLineDropDown
        product={product}
        setProduct={setProduct}
        selectedProductGroupId={selectedProductGroupId}
      />
    </div>
  );
}
