import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { AmplitudeTracking } from 'data/commonTypes';
import ErrorMessageState from 'state/ErrorMessageState';
import { track } from '@amplitude/analytics-browser';

interface Props {
  amplitudeTracking?: AmplitudeTracking;
}

export default function ErrorTag({ amplitudeTracking }: Props): React.ReactElement {
  const errorMessage = useRecoilValue(ErrorMessageState);

  if (amplitudeTracking && errorMessage.amplitudeErrorMessage) {
    amplitudeTracking.data.error = errorMessage.amplitudeErrorMessage;
    track(amplitudeTracking.eventName, amplitudeTracking.data);
  }
  return (
    <>
      {errorMessage.amplitudeErrorMessage && (
        <div className="error-box">
          <FontAwesomeIcon
            icon={'exclamation-circle' as IconName}
            aria-hidden="true"
            title="Exclamation error"
          />
          <span aria-roledescription="alert">{errorMessage.errorMessage}</span>
        </div>
      )}
    </>
  );
}
