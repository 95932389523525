import React from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { CurrentOrgSelector } from 'state/OrgsForDropDownState';
import useScreenSize from 'hooks/useScreenSize';
import Link from 'common/Link/Link';
import ProductNavigation from 'common/ProductNavigation/ProductNavigation';
import { track } from '@amplitude/analytics-browser';
import EditPencil from '../EditPencil/EditPencil';
import PermissionService from 'services/PermissionService';
import { CurrentProductSelector } from 'state/ProductsForOrgState';
import { CDSIDFromTokenSelector, IsOrgAdminSelector } from 'state/AccessTokenState';

interface Props {
  superHeaderText: React.ReactElement;
  productName: string;
  setEditing?: (editing: boolean) => void;
  productLink?: boolean;
}
export function Header({
  superHeaderText,
  productName,
  setEditing,
  productLink,
}: Props): React.ReactElement {
  const { org = '', productId = '' } = useParams();
  const organization = useRecoilValue(CurrentOrgSelector(org));
  const [currentProduct] = useRecoilState(CurrentProductSelector(productId));
  const cdsid = useRecoilValue(CDSIDFromTokenSelector);
  const isOrgAdmin = useRecoilValue(IsOrgAdminSelector);
  const isDesktop = useScreenSize();

  function isOnDesktopAndCanEdit(): boolean {
    return (
      (PermissionService.isTeamMemberOrAdmin(cdsid, currentProduct.teamMembers) || isOrgAdmin) &&
      isDesktop
    );
  }

  return (
    <div className="product-header sticky">
      <div className="content-container header-content">
        <h6 data-testid="header-product-hierarchy">
          <Link
            to={`/${organization.url}`}
            data-testid={'org-link-header'}
            onClick={() =>
              track('click breadcrumb', {
                org: `${organization.name} | ${organization.id}`,
                'navigation-level': 'org',
                'app location': 'blue bar',
              })
            }
          >
            {organization.name}
          </Link>
          {superHeaderText}
        </h6>
        <div className="header-container">
          <h3 className="section-heading">
            <div className="heading-with-pencil">
              {productName}
              {isOnDesktopAndCanEdit() && setEditing && (
                <EditPencil
                  onClick={() => setEditing(true)}
                  className="icon-white"
                  dataTestID={'edit-product-taxonomy'}
                  ariaLabel={'edit product name and taxonomy'}
                />
              )}
            </div>
          </h3>

          {isDesktop && productLink && <ProductNavigation isHeader={true} />}
        </div>
      </div>
    </div>
  );
}
