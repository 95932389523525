import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { GetUpdatePageTemplateSectionByTitle } from 'state/OrgDetailsState';
import Icon from 'common/Icon/Icon';
import RichTextInputBox from 'common/RichTextInputBox/RichTextInputBox';
import { SectionImageGallery } from 'common/SectionImageGallery/SectionImageGallery';
import { Actions } from '../UpdateTab';
import { PageSection, ParentType } from 'types/Page';

import './SectionComponent.scss';

interface Props {
  section: PageSection;
  action: Actions;
  setSectionContent: (content: string, title: string) => void;
  setDisabled: (disabled: boolean) => void;
  templateType: ParentType;
}

export function SectionComponent({
  section,
  action,
  setSectionContent,
  setDisabled,
  templateType,
}: Props): React.ReactElement {
  const { title, content } = { ...section, content: section.content ? section.content : '' };
  const [newContent, setNewContent] = useState(section.content ?? '');

  const templateSection = useRecoilValue(
    GetUpdatePageTemplateSectionByTitle({ title, templateType }),
  );

  useEffect(() => {
    setNewContent(section.content ?? '');
  }, [section.content]);

  function cleanSectionTitle(sectionTitle: string): string {
    return sectionTitle.replace(/[^A-Za-z]/g, '').toLowerCase();
  }

  return (
    <div className="update-box-wrapper">
      <div className="update-box-header-flex">
        <h5 className={`update-box-title-flex bold ${cleanSectionTitle(title)}`}>
          {templateSection && templateSection.icon && (
            <Icon name={templateSection.icon} className="update-box-icon" />
          )}
          {title}
        </h5>
        <SectionImageGallery content={content} title={title} page="update" />
      </div>
      {action === Actions.view && (
        <span
          data-testid={`section-${cleanSectionTitle(title)}`}
          className="update-box-content"
          dangerouslySetInnerHTML={{ __html: content }}
        ></span>
      )}
      {(action === Actions.add || action === Actions.edit) && (
        <RichTextInputBox
          id={`${cleanSectionTitle(title)}`}
          content={newContent}
          setSection={(content: string, title: string) => {
            setSectionContent(content, title);
            setNewContent(content);
          }}
          title={title}
          setDisabled={setDisabled}
          componentName={'update'}
        />
      )}
    </div>
  );
}
