import AddProductForm from './AddProductForm/AddProductForm';

import React from 'react';
import ErrorMessageView from 'common/ErrorMessageView/ErrorMessageView';
import { enableFeatureDefaultTrue, formatAndRemoveSpacesFromName } from 'utils/utils';
import { useRemoteConfig } from 'react-flagsmith/useRemoteConfig';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { CurrentOrgSelector } from 'state/OrgsForDropDownState';
import { CDSIDFromTokenSelector } from 'state/AccessTokenState';
import { OrgDetailsState } from 'state/OrgDetailsState';

export default function AddProductModalContent(): React.ReactElement {
  const { org = '' } = useParams();
  const organization = useRecoilValue(CurrentOrgSelector(org));
  const orgDetails = useRecoilValue(OrgDetailsState);
  const cdsid = useRecoilValue(CDSIDFromTokenSelector);
  const usersList = useRemoteConfig<string[] | string>(
    `users_that_can_add_product_to_${formatAndRemoveSpacesFromName(organization.name)}`,
  );

  if (enableFeatureDefaultTrue(usersList, cdsid)) {
    return (
      <AddProductForm orgDetails={orgDetails} icon={organization.icon} orgUrl={organization.url} />
    );
  }

  return (
    <>
      <ErrorMessageView
        error={`Add Product Feature is not yet released for ${orgDetails.name}`}
        componentName="AddProduct Form"
      />
    </>
  );
}
