import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { CDSIDFromTokenSelector } from 'state/AccessTokenState';
import AuthGuard from 'adfs/AuthGuard';
import { FlagsmithProvider } from 'react-flagsmith/FlagsmithProvider';
import App from 'App';
import { identify, Identify } from '@amplitude/analytics-browser';

const FLAGSMITH_URL = 'https://flagsmith.tools.labs.ford.com/api/v1/';

export default function AppWrapper(): React.ReactElement {
  const cdsid = useRecoilValue(CDSIDFromTokenSelector);

  function setAmplitudeUserProperties() {
    const identifyEvent = new Identify();

    identifyEvent.set('screen height', window.innerHeight);
    identifyEvent.set('screen width', window.innerWidth);
    identifyEvent.set(
      'mouseflow session id',
      //@ts-ignore
      window.mouseflow ? window.mouseflow.getSessionId() : undefined,
    );
    identify(identifyEvent);
  }

  useEffect(() => {
    setAmplitudeUserProperties();
  }, []);

  return (
    <AuthGuard>
      <FlagsmithProvider api={FLAGSMITH_URL} userId={cdsid}>
        <App />
      </FlagsmithProvider>
    </AuthGuard>
  );
}
