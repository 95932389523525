import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Event } from 'data/commonTypes';
import { OrgDetailsState } from 'state/OrgDetailsState';
import { CurrentProductSelector } from 'state/ProductsForOrgState';
import Modal from 'common/Modal/DepricatedModal/Modal';
import { track } from '@amplitude/analytics-browser';
import Product from 'types/Product';
import { TaxonomyInput } from 'common/TaxonomyInput/TaxonomyInput';

interface Props {
  setEditing: (editing: boolean) => void;
  saveProduct: (nameOfUpdatedField: string, productToUpdate: Product) => Promise<void>;
}

export default function ProductTaxonomyModal({
  setEditing,
  saveProduct,
}: Props): React.ReactElement {
  const { productId = '' } = useParams();

  const orgDetails = useRecoilValue(OrgDetailsState);
  const [currentProduct, setCurrentProduct] = useRecoilState(CurrentProductSelector(productId));

  const [newProduct, setNewProduct] = useState<Product>({ ...currentProduct });

  function updateProductTaxonomy(name: string, productLineId: string): Promise<void> {
    if (name !== currentProduct.name) {
      trackEdit(Event.EDIT, 'name', name);
    }
    if (productLineId !== currentProduct.productLineId) {
      trackEdit(Event.EDIT, 'taxonomy productLine');
    }
    if (currentProduct && currentProduct.id) {
      return saveProduct('taxonomy', {
        ...currentProduct,
        name,
        productLineId,
      }).then(() => {
        setCurrentProduct((prevProduct: Product) => {
          return { ...prevProduct, name, productLineId };
        });
      });
    }
    return Promise.resolve();
  }
  function trackEdit(event: Event, field: string, newProductName?: string) {
    track(`${event.toLowerCase()} ${field}`, {
      org: `${orgDetails.name} | ${orgDetails.id}`,
      product: `${newProductName ? newProductName : currentProduct.name} | ${currentProduct.id}`,
      'app location': 'product',
    });
  }

  return (
    <Modal
      title="Edit Taxonomy"
      isOpenModal={true}
      closeHandler={() => setEditing(false)}
      showCloseIcon={true}
    >
      <form
        className="dialog-wrapper"
        onSubmit={(e) => {
          e.preventDefault();
          updateProductTaxonomy(newProduct.name, newProduct.productLineId).then(() =>
            setEditing(false),
          );
        }}
      >
        <TaxonomyInput
          orgDetails={orgDetails}
          location="edit product taxanomy"
          product={newProduct}
          setProduct={setNewProduct}
        />
        <button type="submit" className="button-blue" data-testid="save-taxonomy">
          Save
        </button>
      </form>
    </Modal>
  );
}
