import React, { useEffect } from 'react';
import noUpdateImage from 'images/no-update-image.svg';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { CurrentOrgSelector } from 'state/OrgsForDropDownState';
import { CurrentProductSelector } from 'state/ProductsForOrgState';
import { track } from '@amplitude/analytics-browser';

import './NoUpdatesMessage.scss';

interface Props {
  isDesktopAndTeamMemberOrAdmin: boolean;
  setShowAddNewUpdateModal: (showAddNewUpdateModal: boolean) => void;
}
export default function NoUpdatesMessage({
  isDesktopAndTeamMemberOrAdmin,
  setShowAddNewUpdateModal,
}: Props): React.ReactElement {
  const { productId = '', org = '' } = useParams();
  const currentProduct = useRecoilValue(CurrentProductSelector(productId));
  const organization = useRecoilValue(CurrentOrgSelector(org));

  useEffect(() => {
    if (currentProduct.name !== '') {
      track('view no updates available', {
        org: `${organization.name} | ${organization.id}`,
        product: `${currentProduct.name} | ${currentProduct.id}`,
      });
    }
  }, [currentProduct.id]);

  return (
    <>
      <div className="no-update-message-container" data-testid={'no-updates-message'}>
        <img src={noUpdateImage} alt="Abstract team member putting report on web page" />
        <h4 className="semi-bold">No updates added yet!</h4>
        {isDesktopAndTeamMemberOrAdmin && (
          <div>
            <p>
              Add your first update to share product progress on deliverables, learnings,
              objectives, and more.
            </p>
            <button className="button-blue" onClick={() => setShowAddNewUpdateModal(true)}>
              add new update
            </button>
          </div>
        )}
        {!isDesktopAndTeamMemberOrAdmin && (
          <div>
            <p>
              There are no updates for this product. Please contact the team for product progress.
            </p>
          </div>
        )}
      </div>
    </>
  );
}
