import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ProductHierarchyByIdInState } from 'state/ProductHierarchyState';
import DeleteItemConfirmation from 'common/DeleteItemConfirmation/DeleteItemConfirmation';
import { OrgDetailsState } from 'state/OrgDetailsState';
import { CDSIDFromTokenSelector } from 'state/AccessTokenState';
import { track } from '@amplitude/analytics-browser';
import ProductHierarchy from 'types/ProductHierarchy';
import ProductHierarchyService from 'services/api/ProductHierarchyService';

interface Props {
  name: string;
  id: string;
  title: string;
  type: string;
}

function DeleteProductHierarchyConfirmation(props: Props) {
  const { name, id, title, type } = props;
  const setProductHierarchyInState = useSetRecoilState(ProductHierarchyByIdInState(id));
  const orgDetails = useRecoilValue(OrgDetailsState);
  const cdsid = useRecoilValue(CDSIDFromTokenSelector);

  function trackEvent(eventName: string) {
    track(eventName, {
      org: `${orgDetails.name} | ${orgDetails.id}`,
      cdsid: cdsid,
    });
  }

  const handleDelete = (success: () => void, failure: () => void) => {
    trackEvent(`delete ${type}`);
    ProductHierarchyService.deleteProductHierarchyById(id)
      .then(() => {
        setProductHierarchyInState({} as ProductHierarchy);
        success();
      })
      .catch(() => {
        failure();
      });
  };

  return (
    <DeleteItemConfirmation name={name} title={title} handleDelete={handleDelete} type={type} />
  );
}

export default DeleteProductHierarchyConfirmation;
