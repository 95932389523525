import flagsmith from 'flagsmith';
import { useContext, useEffect, useState } from 'react';
import { FlagsmithContext } from './FlagsmithContext';

export function useRemoteConfig<T extends string | number | boolean | string[]>(
  key: string,
): T | null {
  const flags = useContext(FlagsmithContext);
  const [config, setConfig] = useState<T | null>(getFlagsmithValue(key));

  useEffect(() => {
    setConfig(getFlagsmithValue(key));
  }, [flags, key]);
  function getFlagsmithValue(key: string): T | null {
    const value = flagsmith.getValue(key);
    if (typeof value === 'string' && value.includes('|')) {
      return value.split('|') as T;
    }
    return value as T | null;
  }

  return config;
}
