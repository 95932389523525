import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useRemoteConfig } from 'react-flagsmith/useRemoteConfig';
import useScreenSize from 'hooks/useScreenSize';
import { track } from '@amplitude/analytics-browser';

import './ExperimentBanner.scss';

export default function ExperimentBanner(): React.ReactElement {
  const isDesktop = useScreenSize();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const flagsmithBannerMessage = useRemoteConfig<string[] | string>('experiment_banner_message');

  function getPlainText() {
    return Array.isArray(flagsmithBannerMessage)
      ? flagsmithBannerMessage[0]
      : flagsmithBannerMessage;
  }

  function hasLink() {
    return Array.isArray(flagsmithBannerMessage) && flagsmithBannerMessage.length > 2;
  }

  function trackEvent(interaction: string) {
    track('click top banner', {
      interaction: interaction,
      content: getPlainText(),
    });
  }

  return (
    <>
      {isDesktop && isOpen && flagsmithBannerMessage && (
        <div className="experiment-banner">
          <div className="content-container">
            <span data-testid="experiment-banner-text">{getPlainText()}&nbsp;</span>
            {hasLink() && (
              <a
                href={flagsmithBannerMessage[2]}
                target="_blank"
                rel="noreferrer"
                onClick={() => trackEvent('message')}
              >
                {flagsmithBannerMessage[1]}
              </a>
            )}
            <button
              data-testid="close-button"
              aria-label="close-button"
              className="close-button"
              onClick={() => {
                setIsOpen(false);
                trackEvent('hide');
              }}
            >
              <FontAwesomeIcon icon={'times' as IconName} />
            </button>
          </div>
        </div>
      )}
    </>
  );
}
