import React from 'react';
import ProductHierarchy from 'types/ProductHierarchy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import DeleteProductHierarchyConfirmation from '../DeleteProductHierarchyConfirmation/DeleteProductHierarchyConfirmation';
import Product from 'types/Product';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ProductsState } from 'state/ProductsForOrgState';
import { OrgDetailsState } from 'state/OrgDetailsState';
import { ModalContentsState } from 'state/ModalContentsState';

export default function LinesForGroup(
  lines: ProductHierarchy[],
  isEditMode: boolean,
  toolBarHandler: {
    edit: (productHierarchy: ProductHierarchy | undefined) => void;
  },
): React.ReactElement[] {
  const products = useRecoilValue(ProductsState);
  const orgDetails = useRecoilValue(OrgDetailsState);
  const setModalContents = useSetRecoilState(ModalContentsState);
  const productForLineIdPredicate = (lineId: string) => {
    return (product: Product) => product.productLineId == lineId;
  };

  return lines.map((line: ProductHierarchy) => {
    const productsOnLine =
      products.state == 'Loaded'
        ? products.products.filter(productForLineIdPredicate(line.id))
        : [];

    const archivedProductCount = productsOnLine.filter((product) => product.done == true).length;
    const activeProductCount = productsOnLine.length - archivedProductCount;

    const sentenceArray = [];

    if (activeProductCount > 0) sentenceArray.push(`${activeProductCount} active`);
    if (archivedProductCount > 0)
      sentenceArray.push(`${archivedProductCount} ${orgDetails.doneText?.toLowerCase()}`);

    const sentence = sentenceArray.length == 0 ? '0' : sentenceArray.join(' & ');

    const pluralizeProducts =
      (activeProductCount != 1 && archivedProductCount == 0) || archivedProductCount > 1
        ? ' products'
        : ' product';
    const lineHasNoProducts = productsOnLine.length === 0;

    return (
      <div className="lines" key={`line-${line.name}`}>
        <span>
          {line.name}
          {isEditMode && <div className="product-count">{sentence + pluralizeProducts}</div>}
        </span>
        {isEditMode && (
          <div className="tool-bar">
            <button
              data-testid={`${line.name}-edit-pencil`}
              aria-label="edit lines"
              className="edit-pencil"
              onClick={() => toolBarHandler.edit(line)}
            >
              <FontAwesomeIcon icon={'pen' as IconName} />
            </button>
            {lineHasNoProducts && (
              <button
                aria-label="delete group"
                className="trash-icon"
                onClick={() =>
                  setModalContents({
                    title: 'Delete Line',
                    component: (
                      <DeleteProductHierarchyConfirmation
                        name={line.name}
                        id={line.id}
                        title={'Delete Line?'}
                        type={'line'}
                      />
                    ),
                  })
                }
              >
                <FontAwesomeIcon icon={'trash-can' as IconName} />
              </button>
            )}
          </div>
        )}
      </div>
    );
  });
}
